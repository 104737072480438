<div class="search-control" [formGroup]="form">
  <div class="search-header">
    <div class="form-group">
      <mat-form-field>
        <mat-select formControlName="SearchType" placeholder="Search For" (selectionChange)="searchTypeChange($event)">
          <mat-option [value]="1">Activities</mat-option>
          <mat-option [value]="2">Properties</mat-option>
          <mat-option [value]="3">Categories</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    @if (form.get('SearchType').value===0) {
      <mat-form-field style="width:unset; padding: 0 0 0 1.15rem">
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="sdatepicker" formControlName="StartDate" placeholder="Start Date" (dateChange)="startDateChange($event)" autocomplete="off">
        <mat-datepicker-toggle matSuffix [for]="sdatepicker"></mat-datepicker-toggle>
        <mat-datepicker #sdatepicker startView="month" [startAt]="form.get('StartDate').value"></mat-datepicker>
      </mat-form-field>
    }
    @if (form.get('SearchType').value===0) {
      <mat-form-field style="width: unset; padding: 0 0 0 1.15rem">
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="enddatepicker" formControlName="EndDate" placeholder="End Date" [min]="form.get('EndDate').value" (dateChange)="endDateChange($event)" autocomplete="off">
        <mat-datepicker-toggle matSuffix [for]="enddatepicker"></mat-datepicker-toggle>
        <mat-datepicker #enddatepicker startView="month" [startAt]="form.get('EndDate').value"></mat-datepicker>
      </mat-form-field>
    }
    @if (form.get('SearchType').value===0) {
      <kendo-multiselect [autoClose]="false"  [data]="calendarsService.stations()"
        [textField]="'StationName'"
        [kendoDropDownFilter]="filterSettings"
        [valueField]="'Id'"
        [valuePrimitive]="false"
        [size]="'medium'"
        [fillMode]="'flat'"
        formControlName="Stations"
        placeholder="All Platform Providers"
        style="width: 28rem; padding: 0 0 0 1.6rem; margin-top: 14px">
      </kendo-multiselect>
    }
    @if (form.get('SearchType').value===0) {
      <kendo-multiselect [autoClose]="false"  [data]="calendarsService.stations()"
        [textField]="'StationName'"
        [kendoDropDownFilter]="filterSettings"
        [valueField]="'Id'"
        [valuePrimitive]="false"
        [size]="'medium'"
        [fillMode]="'flat'"
        formControlName="StationsP"
        placeholder="All Platform Providers"
        style="width: 28rem; padding: 0 0 0 1.6rem; margin-top: 14px">
      </kendo-multiselect>
    }
    @if (showOnline) {
      <div class="form-group">
        <div class="checkbox-form-group">
          <mat-checkbox formControlName="IsOnline">Is Digital</mat-checkbox>
        </div>
      </div>
    }
  </div>
  <div class="search-content">
    <search-activities [style.visibility]="form.get('SearchType').value===1 ? 'visible' : 'hidden'" [isOnline]="form.get('IsOnline').value" [stations]="form.get('Stations').value" [startDate]="form.get('StartDate').value" [endDate]="form.get('EndDate').value" class="search-content-control-activity"></search-activities>
    <search-properties [style.visibility]="form.get('SearchType').value===2 ? 'visible' : 'hidden'" [isOnline]="form.get('IsOnline').value" [stations]="form.get('StationsP').value" class="search-content-control"></search-properties>
    <search-categories [style.visibility]="form.get('SearchType').value===3 ? 'visible' : 'hidden'" [isOnline]="form.get('IsOnline').value" class="search-content-control"></search-categories>
  </div>
  <div class="search-footer">
  </div>
</div>
