import { AbstractControl } from "@angular/forms";
import { SafeHtml } from "@angular/platform-browser";


export class CalendarCategory
{
  public Id = 0;
  public CategoryName = "";
  public CalendarProperties: CalendarProperty[] = [];
}

export class CalendarProperty
{
  public Id = 0;
  public PropertyName = "";
  public CalendarActivities: CalendarActivity[] = [];
}

export class CalendarActivity
{
  public Id = 0;
  public ClientName = "";
  public Product = "";
  public Tooltip = "";
  public StationId = 0;
  public StatusCode: StatusCode = StatusCode.Pending;
  public IsWeekDivider = false;
  public IsAvailable = false;
  public IsBlocker = false;
  public IsUnAvailable = false;
  public IsPending = false;
  public IsGuaranteed = false;
  public IsUnConfirmedSold = false;
  public IsSold = false;
  public OnHold = false;
  public OnHoldDate: Date;
  public StartDate: Date;
  public EndDate: Date;
  public FirstDateInCalendarRange: Date;
  public LastDateInCalendarRange: Date;
  public PropertyId = 0;
  public Level = 0;
  public DaysInCalendarRange = 0;
}

export class Category
{
  public Id = 0;
  public CategoryName = "";
  public HasOTP = false;
  public HasPageBackground = false;
  public ExternalId: number = null;
  public Disabled = false;
  public SortOrder = 0;
  public ChangedByUserId = 0;
  public Created: Date;
  public Deleted: Date;
  public LastUpdated: Date;
}

export class Property
{
  public Id = 0;
  public IdAndPropertyName = "";
  public PropertyName = "";
  public CategoryId = 0;
  public PropertyType: PropertyType;
  public ProductCategories: ProductCategory[] = [];
  public StartDate: Date = new Date();
  public EndDate: Date = new Date();
  public StartTime: Date;
  public EndTime: Date;
  public IsPermanent = false;
  public ShowInWhatsOn = false;
  public DaysOfWeek: DaysInWeek[] = [];
  public ClientsSoldPerSpot = 1;
  public ClientsProppedPerSpot = 1;
  public PropertyUrl = "";
  public StationSpecificInfo: PropertyStationInfo[] = [];
  public Level = 0;
  public Stations: number[] = [];
  public StationLevels: StationLevel[] = [];
  public Frequency = "";
  public Season = 0;
  public Episode = 0;
  public NoOfEpisodes = 0;
  public Pre = false;
  public Mid = false;
  public Post = false;
  public isOnline = false;
  public ExternalId: number = null;
  public ExternalType: string = null;
  public ExternalCategory: string = null;
  public ChangedByUserId = 0;
  public SortOrder = 0;
  public Disabled = false;
  public Created: Date;
  public Deleted: Date;
  public LastUpdated: Date;
}

export class StationLevel
{
  public StationId = 0;
  public MaxLevel = 1;
}

export class ActivityTabHeader
{
  public headerText = "";
  public headerClass: any;
}

export class ActivityTab
{
  public header: ActivityTabHeader;
  public content: Activity;
  public isCopy = false;
}

export class ActivityCopy
{
  public Id = 0;
  public Activity: Activity;
  public isFrr = false;
  public isPlusClick = false;
}

export class ActivityInfo
{
  public Id = 0;
  public ClientName = "";
  public Product = "";
  public PropertyName = "";
  public PropertyTypeName = "";
  public Status = "";
  public ActivityName = "";
  public ProjectManagerId: number = null;
  public StartDate: Date;
  public EndDate: Date;
}

export class ActivityHeaderTextChangeArgs
{
  public Id = 0;
  public HeaderText = "";
}

export class ActivityStatusChangeArgs
{
  public Id = 0;
  public Status: ActivityStatus;
}

export class Activity
{
  public Id = 0;
  public ActivitiesParameters: ActivitiesByDateRangeParameters = null;
  public CalendarActivityId: number = null;
  public ClientId: number = null;
  public CampaignId = 0;
  public ClientName = "";
  public ClientContact = "";
  public ClientContactEmail = "";
  public ClientContactPhoneNo = "";
  public Product = "";
  public Property: Property;
  public PropertyName = "";
  public CreativeBriefUrl = "";
  public Status = "";
  public CreatedExternally = false;
  public IsForCampaign = false;
  public ActivityHeader = "";
  public Level = 0;
  public Recurrence: Recurrence;
  public ProductCategories: ProductCategory[] = [];
  public ActivityStationInfos: ActivityStationInfo[] = [];
  public ActivityExtensions: ActivityExtension[] = [];
  public Stations: number[] = [];
  public Scripts = 0;
  public CreativeSets = 0;
  public CreditLine = "";
  public CreditLineDueDate: Date;
  public CreditLineNotes = "";
  public CreditLineTypeId = 0;
  public CreditLineRotationTypeId = 0;
  public MinorPrize = "";
  public MinorPrizeHowFulFilled = "";
  public MinorPrizeValue = 0;
  public MinorPrizeQuantity = 0;
  public MajorPrize = "";
  public MajorPrizeHowFulFilled = "";
  public MajorPrizeValue = 0;
  public MajorPrizeQuantity = 0;
  public StartDate: Date;
  public EndDate: Date;
  public CreationDate: Date;
  public DropDead: Date;
  public ClientExecUserId: number = null;
  public ClientSupportUserId: number = null;
  public BriefManagerUserId: number = null;
  public NatProjectManagerUserId: number = null;
  public ProgrammerUserId: number = null;
  public WebsiteUrl = "";
  public IsGuaranteed = false;
  public IsUnConfirmedSold = false;
  public IsSold = false;
  public SoldDate: Date;
  public OnHold = false;
  public OnHoldDate: Date;
  public CreatedAsFRR = false;
  public IsCopy = false;
  public IsPlusClick = false;
  public MechanicType: MechanicType;
  public MechanicDescription = "";
  public ShowInWhatsOnCalendar = false;
  public Digital = false;
  public HasVideo = false;
  public HasAudio = false;
  public HasPhotos = false;
  public HasEntryForm = false;
  public LogoUrl = "";
  public ImageUrl = "";
  public Newsletter = 0;
  public DailyUpdate = "";
  public BookingId = "";
  public SoldFormUrl = "";
  public FirstDayAirCheckDate: Date;
  public FirstDayAirCheckNo = 0;
  public ScreenShotsDate: Date;
  public ScreenShotsNo = 0;
  public FinalPCRDate: Date;
  public FinalPCRAirCheck = 0;
  public FinalPCRAirPhotos = 0;
  public FinalPCRAirVideos = 0;
  public CrossBookedWithActivity = false;
  public PreRecorded = false;
  public DateDueToAdOps: Date;
  public HostSocialPostedDate: Date;
  public Exclusive = false;
  public PCRSpecialRequest = "";
  public PhotoBrief = "";
  public VideoBrief = "";
  public Pre = 0;
  public Mid = 0;
  public Post = 0;
  public PlaformAsset = "";
  public FurtherDetails = "";
  public SocialBriefDetails = "";
  public BrandToneKeyMessaging = "";
  public IsProductSupplied = false;
  public IsExtensionApproved = false;
  public IsConfidential = false;
  public IsBlocker = false;
  public isOnline = false;
  public CanConflictProductCategory = false;
  public ExternalId: number = null;
  public ExternalLineNo: number = null;
  public Disabled = false;
  public SortOrder = 0;
  public ChangedByUserId = 0;
  public Created: Date;
  public Deleted: Date;
  public LastUpdated: Date;
}

export class ActivityExtension
{
  public Id = 0;
  public ActivityId = 0;
  public Extension: Extension;
  public ExtensionDate: Date;
  public Deliverables = "";
  public Talent = "";
  public ExtensionTiming = "";
  public TotalCost = 0;
  public PlaformAsset = "";
  public FurtherDetails = "";
  public SocialBriefDetails = "";
  public BrandToneKeyMessaging = "";
  public IsProductSupplied = false;
  public IsExtensionApproved = false;
  public ChangedByUserId = 0;
  public LastUpdated: Date;
}

export class Extension
{
  public Id = 0;
  public Name = "";
  public Disabled = false;
  public SortOrder = 0;
  public ChangedByUserId = 0;
  public LastUpdated: Date;
}

export class Station
{
  public Id = 0;
  public StationName = "";
  public City = "";
  public MarketCode = "";
  public State = "";
  public StationType: StationType;
  public StationTypeId = 0;
  public PlatformType: PlatformType;
  public PlatformTypeId = 1;
  public VendorTypeId: number = null;
  public Contact = "";
  public ContactEmail = "";
  public ContactPhoneNo = "";
  public DaysUntilDropDead = 21;
  public ExternalId: number = null;
  public Disabled = false;
  public SortOrder = 0;
  public ChangedByUserId = 0;
  public LastUpdated: Date;
}

export class StationType
{
  public Id = 0;
  public StationTypeName = "";
  public Disabled = false;
  public SortOrder = 0;
  public ChangedByUserId = 0;
  public LastUpdated: Date;
}

export class PropertyType
{
  public Id = 0;
  public PropertyTypeName = "";
  public AirtimeCreditsText = "";
  public CanHaveFirstRightOfRefusal = false;
  public CanAppearInWhatsOnCalendar = false;
  public IsBroadcast = false;
  public IsOnline = false;
  public SortOrder = 0;
  public Disabled = false;
  public ChangedByUserId = 0;
  public LastUpdated: Date;
}

export class PlatformType
{
  public Id = 0;
  public Name = "";
}

export class VendorType
{
  public Id = 0;
  public Name = "";
}

export class ContactType
{
  public Id = 0;
  public ContactTypeName = "";
  public Disabled = false;
  public SortOrder = 0;
  public ChangedByUserId = 0;
  public LastUpdated: Date;
}

export class PropertyExclusionDate
{
  public PropertyId: number;
  public StationId: number;
  public ExclusionDate: Date;
  public ChangedByUserId: number;
  public LastUpdated: Date;
}

export class PropertyStationInfo
{
  public Id = 0;
  public PropertyId = 0;
  public StationId = 0;
  public ClientsSoldPerSpot = 1;
  public Rate = 0;
  public IsRateCpm = false;
  public MediaValue = 0;
  public ProjectManagerId: number = null;
  public AdOpsId: number = null;
  public OnAirCross = false;
  public OnAirTime: Date;
  public PropertyCreditInfos: PropertyCreditInfo[] = [];
  public ExclusionDates: PropertyExclusionDate[] = [];
  public RateCardUrl = "";
  public Disabled = false;
  public ChangedByUserId = 0;
  public LastUpdated: Date;
}

export class ActivityStationInfo
{
  public Id = 0;
  public ActivityId = 0;
  public StationId = 0;
  public ProjectManagerId: number = null;
  public AdOpsId: number = null;
  public ActivityName = "";
  public CreditLineKeyNo = "";
  public AirtimeBreakdown = "";
  public Mechanic = "";
  public AdditionalInfo = "";
  public AdditionalInfoUrl = "";
  public ApprovedScriptUrl = "";
  public Rate = 0;
  public IsRateCpm = false;
  public MultimediaRecovery = 0;
  public Revenue: number = null;
  public Production = 0;
  public StartTime: Date;
  public EndTime: Date;
  public OnAirTime: Date;
  public SignageRequired = "";
  public LocationAddress = "";
  public WhereisUrl = "";
  public OnsiteContactName = "";
  public OnsiteContactTel = "";
  public ParkingInstructions = "";
  public BroadcastingLive = false;
  public TicketingOutlet = "";
  public TicketingAllocation = "";
  public HasOTP = false;
  public IsFreeOfCharge = false;
  public HasPageBackground = false;
  public NoOfPageBackgrounds = 0;
  public ActivityCreditInfos: ActivityCreditInfo[] = [];
  public Disabled = false;
  public ChangedByUserId = 0;
  public LastUpdated: Date;
}

export class Client
{
  public Id = 0;
  public ExternalId = 0;
  public Name = "";
  public LongName = "";
  public Contact = "";
  public ContactEmail = "";
  public ContactPhoneNo = "";
  public SortOrder = 0;
  public Disabled = false;
  public ChangedByUserId = 0;
  public LastUpdated: Date;
}

export class ListItem
{
  public Id = 0;
  public Name = "";
}

export class MechanicType
{
  public Id = 0;
  public MechanicTypeName = "";
  public MechanicLabelText = "";
  public Disabled = false;
  public SortOrder = 0;
  public ChangedByUserId = 0;
  public LastUpdated: Date;
}

export class DateInformation
{
  public CalendarDate: Date;
  public PropertyId = 0;
  public Description = "";
  public Stations: Station[] = [];
  public ColumnSpan = 1;
  public Status: SpotStatus;
  public Tooltip = "";
}

export class CalendarDataRecord
{
  public CalendarCategories: CalendarCategory[] = [];
  public ActivityIds: number[] = [];
  public PropertyIds: number[] = [];
}

export class ActivityStatus
{
  public Name = "";
  public Value = "";
}

export class WeekListItem 
{
  public Id: number;
  public WeekNo: number;
  public Year: number;
  public Name: string;
  public WeekDate: Date;
  public WeekDateString: string;
}

export enum SpotStatus
{
  NotAvailable = 0,
  Available = 1,
  Pending = 2,
  OnHold = 5,
  UnConfirmedSold = 8,
  Guaranteed = 12,
  Sold = 15
}


export enum StatusCode
{
  Pending = 2,
  OnHold = 5,
  UnConfirmedSold = 8,
  Guaranteed = 12,
  Sold = 15
}

export enum ContactTypeId
{
  ClientExecutive = 1,
  ClientSupport = 2,
  BriefManager = 3,
  ProjectManager = 4,
  NationalProjectManager = 5,
  Programmer = 6,
  AdOps = 7
}

export class CalendarParameters
{
  public CategoryIds: number[] = [];
  public PropertyTypeIds: number[] = [];
  public startDate: string;
  public endDate: string;
  public stationIds: number[];
  public noOfWeeks: number;
  public noOfStations: number;
  public isOnline: boolean;
  public availableOnly: boolean = false;
}

export class ActivitiesParameters 
{
  public propertyId: number;
  public stationIds: number[];
  public spotDate: any;
  public level: number;
}

export class ActivitiesByDateRangeParameters
{
  public propertyId: number;
  public stationIds: number[];
  public startDate: string;
  public endDate: string;
  public level: number;
}

export class EventDaysParameters
{
  public stationIds: number[];
  public startDate: any;
  public endDate: any;
}

export class EventDay
{
  public EventDate: Date;
  public StationId = 0;
  public EventCount = 0;
  public EventsTooltip = "";
}

export class CalendarInfo
{
  public EventDays: EventDay[] = [];
  public CalendarHtml = "";
  public CalendarSafeHtml: SafeHtml = "";
}

export class CalendarStateGroupInfo
{
  public StartDate: Date;
  public EndDate: Date;
  public StationIds: number[] = [];
  public NoOfWeeks = 0;
  public IsOnline = false;
}

export class CalendarDayLetter
{
  public Id = 0;
  public Letter = "";
  public DayNumber = "";
  public EventMonth = 0;
  public EventYear = 0;
  public EventStationId = 0;
  public EventStationTypeId = 0;
  public EventCount = 0;
  public EventsTooltip = "";
  public EventsUrl = "";
  public LastDivider = false;
}

export class DaysInWeek
{
  public Name: string;
  public Abbreviation: string;
  public ShortAbbreviation: string;
  public FirstLetter: string;
  public Value: number;
  public DayOfWeek: number;
}

export class ValidatedControl
{
  public Control: AbstractControl;
  public ControlName: string;
  public TabName: string;
}

export class ProductCategory
{
  public Id: number;
  public ProductCategoryName: string;
  public HasWarning: boolean;
  public SortOrder: number;
  public Disabled: boolean;
  public LastUpdated: Date;
}

export class Recurrence
{
  public Id = 0;
  public RecurrenceType = 0;
  public SelectedDaysInWeek: DaysInWeek[] = [];
  public DayOfMonth: number;
  public DaysOfWeek: number;
  public EveryWeekDay = false;
  public Instance = 0;
  public Interval = 1;
  public MonthOfYear: number;
  public EndBy = false;
  public EndAfter = false;
  public NoEndDate = false;
  public OnSpecificDayOfMonth = false;
  public Occurrences = 0;
  public PatternStartDate: Date;
  public PatternEndDate: Date;
  public FirstOccurrenceDate: Date;
  public LastOccurrenceDate: Date;
  public MinimumDate: Date;
  public MaximumDate: Date;
  public MaximumMonths: number;
  public ChangedByUserId = 0;
  public LastUpdated: Date;
}

export class CreditsInfo
{
  public Id = 0;
  public StationId = 0;
  public DayPartId = 0;
  public DayPartLabel = "";
  public Mon = 0;
  public Tue = 0;
  public Wed = 0;
  public Thu = 0;
  public Fri = 0;
  public Sat = 0;
  public Sun = 0;
  public Disabled: boolean;
  public ChangedByUserId = 0;
  public LastUpdated: Date;
}

export class PropertyCreditInfo extends CreditsInfo
{
  public PropertyId = 0;
}

export class ActivityCreditInfo extends CreditsInfo
{
  public ActivityId = 0;
}



