import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

//////////// Example Below: ////////////////////////////////////////////////////////////////////
//<kendo-button-filter (buttonClicked)="addNew($event)" buttonTitle="add" buttonIcon="add_box">
//</kendo-button-filter>
////////////////////////////////////////////////////////////////////////////////////////////////

@Component({
  selector: 'kendo-button-filter',
  template: `
    <button mat-icon-button color="accent" (click)="onClick($event)" [title]="buttonTitle">
      <i class="material-icons" [innerHTML]="buttonIcon"></i>
    </button>
  `,
  standalone: true,
  imports: [FormsModule, MatButtonModule, MatIconModule]
})
export class ButtonFilterComponent extends BaseFilterCellComponent
{
  @Output() filterSelectionChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() buttonClicked: EventEmitter<any> = new EventEmitter<any>();


  @Input() public buttonTitle: string = "";
  @Input() public buttonIcon: string = "add_box";
  @Input() public override filter: CompositeFilterDescriptor;
  @Input() public filterBy: string; //field to filter the grid's data by.


  constructor(filterService: FilterService)
  {
    super(filterService);
  }

  public onClick(value: any): void
  {
    this.buttonClicked.emit(value);
  }
}
