import { CdkDrag, CdkDragHandle } from "@angular/cdk/drag-drop";

import { ChangeDetectorRef, Component, Inject, Injector, WritableSignal, effect, inject, signal } from "@angular/core";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatOptionModule } from "@angular/material/core";
import { MatDatepickerInputEvent, MatDatepickerModule } from "@angular/material/datepicker";
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { IconDefinition, faBroadcastTower, faClone, faEnvelope, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import { PopupSettings, TimePickerModule } from '@progress/kendo-angular-dateinputs';
import { DropDownFilterSettings, DropDownListModule, MultiSelectModule } from "@progress/kendo-angular-dropdowns";
import { FloatingLabelModule } from "@progress/kendo-angular-label";
import { TooltipModule } from "@progress/kendo-angular-tooltip";
import { injectMutation, injectQuery, QueryClient } from "@tanstack/angular-query-experimental";
import { Subscription } from 'rxjs';
import { AirtimeCreditsComponent } from "../../../shared/components/airtime-credits/airtime-credits.component";
import { ConfirmationDialog } from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { ContactsSelectorComponent } from "../../../shared/components/contacts-selector/contacts-selector.component";
import { DocumentUploaderComponent } from "../../../shared/components/document-uploader/document-uploader.component";
import { MatDialogMultiDatePickerComponent } from "../../../shared/components/mat-dialog-multi-datepicker/mat-dialog-multi-datepicker.component";
import { SelectableChipsComponent } from "../../../shared/components/selectable-chips/selectable-chips.component";
import { DisableControlDirective } from "../../../shared/directives/disable-control-directive";
import { ContactTypeUser, PropertiesInfo } from "../../../shared/models/common.models";
import { GenericFilterPipe } from "../../../shared/pipes/generic-filter.pipe";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { CommonDataService } from "../../../shared/services/common-data.service";
import { CommonService } from '../../../shared/services/common.service';
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { DataHandlerService } from "../../../shared/services/data-handler.service";
import { LoggerService } from "../../../shared/services/logger.service";
import { CalendarsBaseService } from "../calendars-base.service";
import { Category, Property, PropertyCreditInfo, PropertyExclusionDate, PropertyStationInfo, PropertyType, Station, StationLevel } from '../calendars.models';
import { CalendarsService } from "../calendars.service";



@Component({
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss'],
  standalone: true,
  imports: [TooltipModule, CdkDrag, FormsModule, ReactiveFormsModule, CdkDragHandle, MatDialogTitle, MatTabsModule, MatIconModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, MatCheckboxModule, MatDatepickerModule, MatCardModule, SelectableChipsComponent, FloatingLabelModule, MultiSelectModule, DropDownListModule, MatTooltipModule, FaIconComponent, ContactsSelectorComponent, MatDialogMultiDatePickerComponent, DocumentUploaderComponent, DisableControlDirective, TimePickerModule, AirtimeCreditsComponent, MatDialogActions, MatButtonModule, MatProgressSpinnerModule, GenericFilterPipe]
})
export class PropertyComponent 
{
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  public popupSettings: PopupSettings = {
    appendTo: 'root',
    animate: false,
    popupClass: 'popup-class-property'
  };

  private queryClient = new QueryClient();

  private dataHandler = inject(DataHandlerService);
  public configurationService = inject(ConfigurationService);

  private propertyId: WritableSignal<number> = signal(0);

  public updateProperty = injectMutation(() => ({
    mutationFn: (property: any) =>
    {
      return this.calendarsService.updateProperty(property);
    },
    onSuccess: (data: any, variables: any, context: any) =>
    {
      if (this.data.id > 0)
      {
        this.queryClient.invalidateQueries({
          type: "all", exact: false, predicate: (query: any) =>
          {
            return query.queryKey[0] == "Property" && (query.queryKey[1] == null || query.queryKey[1] == variables.Id || query.queryKey[1] == 0);
          }
        });

        this.queryClient.invalidateQueries({
          type: "all", exact: false, predicate: (query: any) =>
          {
            return query.queryKey[0] == "Properties";
          }
        });

        this.queryClient.invalidateQueries({
          type: "all", exact: false, predicate: (query: any) =>
          {
            return query.queryKey[0] == "Calendar";
          }
        });
      }
    }
  }))

  public createProperty = injectMutation(() => ({
    mutationFn: (property: any) =>
    {
      return this.calendarsService.createProperty(property);
    },
    onSuccess: (data: any, variables: any) =>
    {
      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "Calendar";
        }
      });

      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "Properties";
        }
      });

      this.queryClient.prefetchQuery({
        queryKey: ["Property", data.Id],
        queryFn: () => this.calendarsService.getProperty(data.Id),
        //staleTime: this.configurationService.cbSettings().prefetchQueryStaleTime
      });
    }
  }))

  public deleteProperty = injectMutation(() => ({
    mutationFn: (property: any) =>
    {
      return this.calendarsService.deleteProperty(property);
    },
    onSuccess: (data: any, variables: any) =>
    {
      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "Property" && (query.queryKey[1] == null || query.queryKey[1] == variables.Id || query.queryKey[1] == 0);
        }
      });

      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "Properties";
        }
      });

      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "Calendar";
        }
      });
    }
  }))


  public formSSI: FormGroup = new FormGroup({
    Id: new FormControl(),
    PropertyId: new FormControl(),
    StationId: new FormControl(),
    ProjectManagerId: new FormControl(null),
    AdOpsId: new FormControl(null),
    ClientsSoldPerSpot: new FormControl(1, [Validators.required]),
    Rate: new FormControl(0, [Validators.required]),
    IsRateCpm: new FormControl(),
    MediaValue: new FormControl(0, [Validators.required]),
    OnAirCross: new FormControl(),
    OnAirTime: new FormControl(),
    ExclusionDates: new FormControl(),
    PropertyCreditInfos: new FormControl(),
    RateCardUrl: new FormControl(),
    Disabled: new FormControl()
  });

  public form: FormGroup = new FormGroup({
    Id: new FormControl(),
    PropertyName: new FormControl(null, [Validators.required, Validators.pattern(/[\S]/g)]),
    CategoryId: new FormControl(null, Validators.required),
    PropertyType: new FormControl(null, Validators.required),
    StartDate: new FormControl(),
    EndDate: new FormControl(),
    //StartTime: new FormControl(),
    //EndTime: new FormControl(),
    IsPermanent: new FormControl(),
    ShowInWhatsOn: new FormControl(false, Validators.required),
    DaysOfWeek: new FormControl(),
    Frequency: new FormControl(),
    Season: new FormControl(),
    Episode: new FormControl(),
    NoOfEpisodes: new FormControl(),
    Pre: new FormControl(true),
    Mid: new FormControl(),
    Post: new FormControl(),
    ProductCategories: new FormControl(),
    //ClientsSoldPerSpot: new FormControl(),
    ClientsProppedPerSpot: new FormControl(1, [Validators.required]),
    //PropertyUrl: new FormControl(),
    StationSpecificInfo: new FormControl(),
    ExternalType: new FormControl(),
    ExternalCategory: new FormControl(),
    Stations: new FormControl(null, [Validators.required, Validators.minLength(1)]),
    Disabled: new FormControl(),
    SortOrder: new FormControl()
  });



  public exclusionMinDate: Date = this.commonService.today;

  public saveInProgress = false;
  public formControlsDisabled = false;
  public formSSIControlsDisabled = false;

  public formInitiallyDisabled = false;
  public formSSIInitiallyDisabled = false;

  public currentProjectManager: ContactTypeUser;
  public currentAdOps: ContactTypeUser;
  public currentExclusionDates: Date[] = [];

  public _projectManagersForStation: ContactTypeUser[] = [];
  public _adOpsForStation: ContactTypeUser[] = [];

  public faEnvelope: IconDefinition = faEnvelope;
  public faPhone: IconDefinition = faPhone;
  public faUser: IconDefinition = faUser;
  public faBroadcastTower: IconDefinition = faBroadcastTower;
  public faClone: IconDefinition = faClone;

  private _airtimeCreditsLabel = "";
  private _propertyStations: Station[] = [];
  private _currentStationId: number;
  private _isNew = false;
  private _availablePropertyStations: Station[] = [];

  public allowOnlyOnePlatformProvider = false;

  public contactNameLabel: string = "";
  public contactPhoneLabel: string = "";
  public contactEmailLabel: string = "";

  public currentStationContact: string = "";
  public currentStationContactEmail: string = "";
  public currentStationContactPhoneNo: string = "";

  public podcastStationId: number;

  public propertyName = "";

  public calendarsService: CalendarsBaseService;
  private dialogCloseSubscription: Subscription;

  public getProperty = injectQuery(() =>
  ({
    enabled: this.configurationService.cbSettings().calendarServiceUrl.length > 0 && this.propertyId() != null && this.propertyId() > 0,
    queryKey: ["Property", this.propertyId()],
    queryFn: () => this.calendarsService.getProperty(this.propertyId()),
    //staleTime: this.configurationService.cbSettings().queryStaleTime,
  }))

  public isRadioBroadcastPropertyType: WritableSignal<boolean> = signal(true);

  public propertyChanged = effect(() =>
  {
    //Should only run when form loads but changes to isRadioBroadcastPropertyType triggered it and reloaded original data after propertytype change so added this.form.pristine
    if (this.propertyId() != null && this.propertyId() > 0 && this.form.pristine)
    {
      const result: any = this.getProperty.data();

      if (result && result.Id)
      {
        this.propertyName = result.PropertyName;

        this.setupProperty(result);

        this.formInitiallyDisabled = (this.isNew && !this.authorisationService.hasPermission(this.authorisationService.Permissions.AddProperty)) || (!this.isNew && !this.authorisationService.hasPermission(this.authorisationService.Permissions.EditProperty));

        this.disableFormControls(this.formInitiallyDisabled);

        this.setPermanentDateRangeDisabledState(result.IsPermanent);

        this.form.get('Disabled').disable({ emitEvent: false });
        if (this.authorisationService.hasPermission(this.authorisationService.Permissions.CanEnableDisableProperty))
        {
          this.form.get('Disabled').enable({ emitEvent: false });
        }
      }

      this.setAvailablePropertyStations();
    }
  });

  constructor(
    protected injector: Injector,
    private authorisationService: AuthorisationService,
    private dialogRef: MatDialogRef<PropertyComponent>,
    @Inject(MAT_DIALOG_DATA) private data: PropertiesInfo,
    private confirmDialog: MatDialog,
    public commonService: CommonService,
    public commonDataService: CommonDataService,
    private loggerService: LoggerService,
    private cdr: ChangeDetectorRef)
  {
    //if (data.isOnline)
    //{
    //  this.calendarsService = this.injector.get(OnlineCalendarsService);
    //}
    //else
    //{
    this.calendarsService = this.injector.get(CalendarsService);
    //}
  }


  setupProperty(property: Property)
  {
    try
    {
      if (property)
      {
        this.isNew = property.Id < 1;

        if (property.PropertyType)
        {
          this.isRadioBroadcastPropertyType.set(property.PropertyType.IsBroadcast);
        }

        this.allowOnlyOnePlatformProvider = !this.isRadioBroadcastPropertyType() && !this.configurationService.cbSettings().useStationAsGeoTargetAndStationGroupAsVendor;

        if (property.StartDate)
        {
          property.StartDate = this.commonService.parseJsonDate(property.StartDate);
        }

        if (property.EndDate)
        {
          property.EndDate = this.commonService.parseJsonDate(property.EndDate);
        }

        if (property.StationLevels && property.StationLevels.length > 0)
        {
          if (!property.Stations || property.Stations.length == 0)
          {
            property.Stations = [];

            property.StationLevels.map(s =>
            {
              property.Stations.push(s.StationId);
            });
          }
        }

        if (!property.Stations || property.Stations.length == 0)
        {
          property.Stations = [];

          if (this.calendarsService.selectedStations && this.calendarsService.selectedStations.length > 0)
          {
            this.calendarsService.selectedStations[0].PlatformTypeId

            this.calendarsService.selectedStations.map((station: Station) =>
            {
              property.Stations.push(station.Id);
            });
          }
        }

        if (property.Stations && property.Stations.length > 0)
        {
          this.podcastStationId = property.Stations[0];
        }

        //set category default if new property and no category
        if ((!property.CategoryId || property.CategoryId < 1) && property.Id < 1)
        {
          const categoryDefault: Category = this.calendarsService.categories()[0];

          if (categoryDefault)
          {
            property.CategoryId = categoryDefault.Id;
          }
        }

        if (!property.ProductCategories || property.ProductCategories.length == 0)
        {
          property.ProductCategories = [];
        }

        if (property.Stations && property.Stations.length > 0)
        {
          property.Stations.map((stationId: number) =>
          {
            //add new blank station specific info object for station if one doesn't exist.
            if (!property.StationSpecificInfo || !property.StationSpecificInfo.some((ssi: PropertyStationInfo) => stationId == ssi.StationId))
            {
              const newSSI: PropertyStationInfo = new PropertyStationInfo();
              newSSI.StationId = stationId;
              newSSI.PropertyId = property.Id;
              newSSI.ClientsSoldPerSpot = 1;
              newSSI.IsRateCpm = false;
              newSSI.Rate = 0;
              newSSI.MediaValue = 0;
              newSSI.RateCardUrl = "";

              newSSI.PropertyCreditInfos = [];

              this.commonDataService.dayparts().map(dp =>
              {
                if (!dp.Disabled)
                {
                  const pci: PropertyCreditInfo = new PropertyCreditInfo();
                  pci.ChangedByUserId = property.ChangedByUserId;
                  pci.DayPartId = dp.Id;
                  pci.DayPartLabel = dp.DayPartLabel;
                  pci.Mon = 0;
                  pci.Tue = 0;
                  pci.Wed = 0;
                  pci.Thu = 0;
                  pci.Fri = 0;
                  pci.Sat = 0;
                  pci.Sun = 0;
                  pci.PropertyId = property.Id;
                  pci.StationId = stationId;

                  newSSI.PropertyCreditInfos.push(pci);
                }
              });

              if (!property.StationSpecificInfo)
              {
                property.StationSpecificInfo = [];
              }

              property.StationSpecificInfo.push(newSSI);
            }
            else
            {
              const psi: PropertyStationInfo = property.StationSpecificInfo.filter((ssi: PropertyStationInfo) => stationId == ssi.StationId)[0];

              if (psi)
              {
                if (psi.OnAirTime)
                {
                  psi.OnAirTime = this.commonService.parseJsonDate(psi.OnAirTime);
                }

                this.commonDataService.dayparts().map(dp =>
                {
                  if (!dp.Disabled)
                  {
                    if (!psi.PropertyCreditInfos.some((pci: PropertyCreditInfo) => pci.DayPartId == dp.Id))
                    {
                      const pci: PropertyCreditInfo = new PropertyCreditInfo();
                      pci.ChangedByUserId = property.ChangedByUserId;
                      pci.DayPartId = dp.Id;
                      pci.DayPartLabel = dp.DayPartLabel;
                      pci.Mon = 0;
                      pci.Tue = 0;
                      pci.Wed = 0;
                      pci.Thu = 0;
                      pci.Fri = 0;
                      pci.Sat = 0;
                      pci.Sun = 0;
                      pci.PropertyId = property.Id;
                      pci.StationId = stationId;

                      psi.PropertyCreditInfos.push(pci);
                    }
                  }
                });
              }
            }
          });
        }


        this.form.controls["Id"].patchValue(property.Id, { emitEvent: false });
        this.form.controls["PropertyName"].patchValue(property.PropertyName, { emitEvent: false });
        this.form.controls["CategoryId"].patchValue(property.CategoryId, { emitEvent: false });
        this.form.controls["PropertyType"].patchValue(property.PropertyType, { emitEvent: false });
        this.form.controls["StartDate"].patchValue(property.StartDate, { emitEvent: false });
        this.form.controls["EndDate"].patchValue(property.EndDate, { emitEvent: false });
        //this.form.controls["StartTime"].patchValue(property.StartTime, { emitEvent: false });
        //this.form.controls["EndTime"].patchValue(property.EndTime, { emitEvent: false });
        this.form.controls["IsPermanent"].patchValue(property.IsPermanent, { emitEvent: false });
        this.form.controls["ShowInWhatsOn"].patchValue(property.ShowInWhatsOn, { emitEvent: false });
        this.form.controls["DaysOfWeek"].patchValue(property.DaysOfWeek, { emitEvent: false });
        this.form.controls["Frequency"].patchValue(property.Frequency, { emitEvent: false });
        this.form.controls["Season"].patchValue(property.Season, { emitEvent: false });
        this.form.controls["Episode"].patchValue(property.Episode, { emitEvent: false });
        this.form.controls["NoOfEpisodes"].patchValue(property.NoOfEpisodes, { emitEvent: false });
        this.form.controls["Pre"].patchValue(property.Pre, { emitEvent: false });
        this.form.controls["Mid"].patchValue(property.Mid, { emitEvent: false });
        this.form.controls["Post"].patchValue(property.Post, { emitEvent: false });
        this.form.controls["ProductCategories"].patchValue(property.ProductCategories, { emitEvent: false });
        //this.form.controls["ClientsSoldPerSpot"].patchValue(property.ClientsSoldPerSpot, { emitEvent: false });
        this.form.controls["ClientsProppedPerSpot"].patchValue(property.ClientsProppedPerSpot, { emitEvent: false });
        //this.form.controls["PropertyUrl"].patchValue(property.PropertyUrl, { emitEvent: false });
        this.form.controls["StationSpecificInfo"].patchValue(property.StationSpecificInfo, { emitEvent: false });
        this.form.controls["Stations"].patchValue(property.Stations, { emitEvent: false });
        this.form.controls["ExternalCategory"].patchValue(property.ExternalCategory, { emitEvent: false });
        this.form.controls["ExternalType"].patchValue(property.ExternalType, { emitEvent: false });
        this.form.controls["Disabled"].patchValue(property.Disabled, { emitEvent: false });
        this.form.controls["SortOrder"].patchValue(property.SortOrder, { emitEvent: false });

        const psis: PropertyStationInfo[] = this.form.get("StationSpecificInfo").value;

        if (psis && psis.length > 0)
        {
          this.formSSI.patchValue(psis[0], { emitEvent: false });

          this.currentStationId = this.formSSI.get("StationId").value;

          if (this.projectManagersForStation && this.projectManagersForStation.length > 0)
          {
            this.currentProjectManager = this.projectManagersForStation.filter((pm: ContactTypeUser) => pm.Id == this.formSSI.get("ProjectManagerId").value)[0];
          }

          if (this.adOpsForStation && this.adOpsForStation.length > 0)
          {
            this.currentAdOps = this.adOpsForStation.filter((ao: ContactTypeUser) => ao.Id == this.formSSI.get("AdOpsId").value)[0];
          }

          this.setExclusionDatesForStation();
        }

        this.setPropertyStations();

        const station: Station = this.propertyStations.filter((station: Station) => station.Id == this.currentStationId)[0];

        if (station)
        {
          this.currentStationContact = station.Contact;
          this.currentStationContactEmail = station.ContactEmail;
          this.currentStationContactPhoneNo = station.ContactPhoneNo;
        }

        this.setAirtimeCreditsLabel(property.PropertyType);

        this.cdr.detectChanges();
      }
    }
    catch (ex)
    {
    }
  }


  ngOnInit(): void
  {
    if (this.data && this.data.id > 0)
    {
      this.propertyId.set(this.data.id);
    }
    else
    {
      const prop: Property = new Property();
      prop.PropertyName = "New Property";
      prop.Id = 0;
      prop.SortOrder = 0;
      prop.Disabled = false;
      prop.isOnline = false;

      //Default new property days of week to mon-fri
      prop.DaysOfWeek = this.commonDataService.daysInTheWeek.filter(d => (d.Value & this.commonDataService.bitmaskWeekdays) == d.Value);

      const categoryDefault: Category = this.calendarsService.categories()[0];

      if (categoryDefault)
      {
        prop.CategoryId = categoryDefault.Id;
      }

      this.propertyId.set(prop.Id);
      this.propertyName = prop.PropertyName;

      this.setupProperty(prop);

      this.formInitiallyDisabled = (this.isNew && !this.authorisationService.hasPermission(this.authorisationService.Permissions.AddProperty)) || (!this.isNew && !this.authorisationService.hasPermission(this.authorisationService.Permissions.EditProperty));

      this.disableFormControls(this.formInitiallyDisabled);

      this.setPermanentDateRangeDisabledState(prop.IsPermanent);
    }

    this.setAvailablePropertyStations();

    //Show required fields in red
    this.form.markAllAsTouched();
    this.formSSI.markAllAsTouched();
  }

  disableFormControls(disable: boolean)
  {
    this.formControlsDisabled = disable;

    if (disable)
    {
      this.form.get('PropertyName').disable({ emitEvent: false });
      this.form.get('PropertyType').disable({ emitEvent: false });
      this.form.get('IsPermanent').disable({ emitEvent: false });
      this.form.get('ShowInWhatsOn').disable({ emitEvent: false });
      this.form.get('ClientsProppedPerSpot').disable({ emitEvent: false });
      this.form.get('CategoryId').disable({ emitEvent: false });
      this.form.get('Stations').disable({ emitEvent: false });
      this.form.get('ExternalCategory').disable({ emitEvent: false });
      this.form.get('ExternalType').disable({ emitEvent: false });
      this.form.get('SortOrder').disable({ emitEvent: false });
    }
    else
    {
      this.form.get('PropertyName').enable({ emitEvent: false });
      this.form.get('PropertyType').enable({ emitEvent: false });
      this.form.get('IsPermanent').enable({ emitEvent: false });
      this.form.get('ShowInWhatsOn').enable({ emitEvent: false });
      this.form.get('ClientsProppedPerSpot').enable({ emitEvent: false });
      this.form.get('CategoryId').enable({ emitEvent: false });
      this.form.get('Stations').enable({ emitEvent: false });
      this.form.get('ExternalCategory').enable({ emitEvent: false });
      this.form.get('ExternalType').enable({ emitEvent: false });
      this.form.get('SortOrder').enable({ emitEvent: false });
    }
  }

  handleProductCategoryChange(event: any)
  {
    this.form.markAsDirty();
  }

  isPermanentChanged(event: any)
  {
    this.setPermanentDateRangeDisabledState(this.form.get('IsPermanent').value);
  }

  setPermanentDateRangeDisabledState(isPermanent: boolean)
  {
    if (isPermanent)
    {
      this.form.get('StartDate').disable({ emitEvent: false });
      this.form.get('EndDate').disable({ emitEvent: false });
    }
    else
    {
      this.form.get('StartDate').enable({ emitEvent: false });
      this.form.get('EndDate').enable({ emitEvent: false });
    }
  }

  stationContactEmailClick(e: any)
  {
    if (this.currentStationContactEmail)
    {
      window.location.href = "mailto:" + this.currentStationContactEmail;
    }
  }

  stationChange(event: any)
  {
    const stationId: number = event.value;

    this.currentStationId = stationId;

    this.setSelectedStationsDetails(this.currentStationId, true);
  }

  startDateChange(event: MatDatepickerInputEvent<Date>)
  {
    if (this.form.get("StartDate").value > this.form.get("EndDate").value)
    {
      //Ensure StartDate is not greater than EndDate
      this.form.controls["EndDate"].patchValue(this.form.get("StartDate").value, { emitEvent: false });
    }
  }

  endDateChange(event: MatDatepickerInputEvent<Date>)
  {
    if (this.form.get("StartDate").value > this.form.get("EndDate").value)
    {
      //Ensure StartDate is not greater than EndDate
      this.form.controls["StartDate"].patchValue(this.form.get("EndDate").value, { emitEvent: false });
    }
  }


  projectManagerChange(event: any)
  {
    //Get current PropertyStationInfo filled with any changed values
    const currentPsi: PropertyStationInfo = this.formSSI.value;

    if (currentPsi)
    {
      // Update current PropertyStationInfo that is bound to the form with project manager from variable which is bound to the control
      currentPsi.ProjectManagerId = this.currentProjectManager?.Id;
    }

    this.formSSI.markAsDirty();
    this.form.markAsDirty();
  }

  adOpsChange(event: any)
  {
    //Get current PropertyStationInfo filled with any changed values
    const currentPsi: PropertyStationInfo = this.formSSI.value;

    if (currentPsi)
    {
      // Update current PropertyStationInfo that is bound to the form with ad ops from variable which is bound to the control
      currentPsi.AdOpsId = this.currentAdOps?.Id;
    }

    this.formSSI.markAsDirty();
    this.form.markAsDirty();
  }

  airtimeCreditChange(event: any)
  {
    this.formSSI.markAsDirty();
    this.form.markAsDirty();
  }

  get propertyStations(): Station[]
  {
    return this._propertyStations;
  }

  setPropertyStations()
  {
    this._propertyStations = this.calendarsService.stations().filter((station: Station) => this.form.get("Stations").value.some((s: number) => s == station.Id));
  }

  get availablePropertyStations(): Station[]
  {
    return this._availablePropertyStations;
  }

  get tabLabel(): string
  {
    let name = "Station";

    if (!this.isRadioBroadcastPropertyType())
    {
      name = "Implementation";
    }

    return name;
  }

  get platformNameLabel(): string
  {
    let name = "Platform Provider"; // "Station";

    if (!this.isRadioBroadcastPropertyType())
    {
      name = "Platform Provider"; // "Internet";
    }

    return name;
  }

  duplicateCurrentStationInfo()
  {
    const confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: false });
    confirmDialogRef.componentInstance.confirmMessage = `Are you sure you want to duplicate the current ${this.platformNameLabel} specific information across all ${this.platformNameLabel}s?`;
    confirmDialogRef.componentInstance.confirmTitle = `Duplicate ${this.platformNameLabel} specific information?`;

    this.dialogCloseSubscription = confirmDialogRef.afterClosed().subscribe((result: any) =>
    {
      this.dialogCloseSubscription.unsubscribe();

      if (result)
      {
        //Get current PropertyStationInfo filled with any changed values
        const currentPsi: PropertyStationInfo = this.formSSI.value;

        if (currentPsi)
        {
          const psis = this.form.get("StationSpecificInfo").value;

          if (psis && psis.length > 0)
          {
            psis.map((psi: PropertyStationInfo) =>
            {
              if (psi != null)
              {
                psi.ExclusionDates = currentPsi.ExclusionDates;

                if (psi.ExclusionDates && psi.ExclusionDates.length > 0)
                {
                  psi.ExclusionDates.map((ped: PropertyExclusionDate) =>
                  {
                    if (ped != null)
                    {
                      ped.PropertyId = psi.PropertyId;
                      ped.StationId = psi.StationId;
                    }
                  });
                }

                psi.ClientsSoldPerSpot = currentPsi.ClientsSoldPerSpot;
                psi.IsRateCpm = currentPsi.IsRateCpm;
                psi.Rate = currentPsi.Rate;
                psi.MediaValue = currentPsi.MediaValue;
                psi.OnAirCross = currentPsi.OnAirCross;
                psi.OnAirTime = currentPsi.OnAirTime;
                psi.RateCardUrl = currentPsi.RateCardUrl;

                psi.PropertyCreditInfos = JSON.parse(JSON.stringify(currentPsi.PropertyCreditInfos));

                psi.PropertyCreditInfos.map(pci =>
                {
                  pci.Id = 0;
                });
              }
            });

            this.form.get("StationSpecificInfo").patchValue(psis, { emitEvent: false });

            this.formSSI.markAsDirty();
            this.form.markAsDirty();
          }
        }
      }
    });
  }


  setAvailablePropertyStations()
  {
    if (this.isRadioBroadcastPropertyType())
    {
      this._availablePropertyStations = this.calendarsService.stations().filter((station: Station) => (station.PlatformTypeId == this.commonService.stationPlatformTypeId));
    }
    else
    {
      this._availablePropertyStations = this.calendarsService.stations().filter((station: Station) => (station.PlatformTypeId == this.commonService.vendorPlatformTypeId));
    }
  }

  propertyTypeChange(event: any)
  {
    this.setAirtimeCreditsLabel(event.value);

    this.isRadioBroadcastPropertyType.set(event.value.IsBroadcast);

    this.form.get("StationSpecificInfo").patchValue([], { emitEvent: false });
    this.form.get("Stations").patchValue([], { emitEvent: false });
    this._propertyStations = [];

    this.currentExclusionDates = [];
    this.currentProjectManager = null;
    this.currentAdOps = null;
    this.currentStationId = null;
    this.formSSI.reset();

    this.setAvailablePropertyStations();

    if (!this.isRadioBroadcastPropertyType())
    {
      this.form.get("Pre").patchValue(true, { emitEvent: false, onlySelf: true });

      //Default property days of week to all days of week
      this.form.controls["DaysOfWeek"].patchValue(this.commonDataService.daysInTheWeek.filter(d => (d.Value & this.commonDataService.bitmaskEveryday) == d.Value), { emitEvent: false });

      if (this.availablePropertyStations && this.availablePropertyStations.length > 0)
      {
        this.currentStationId = this.availablePropertyStations[0].Id;

        this._propertyStations.push(this.availablePropertyStations[0]);

        const stations: number[] = [];
        stations.push(this.currentStationId);

        this.form.get("Stations").patchValue(stations);

        this.setPropertyStationChanges(stations);
      }
    }
    else
    {
      this.setPropertyStations();
    }
  }

  setAirtimeCreditsLabel(propertyType: PropertyType)
  {
    this._airtimeCreditsLabel = "";

    if (propertyType)
    {
      this._airtimeCreditsLabel = propertyType.AirtimeCreditsText;
    }
  }

  get airtimeCreditsLabel(): string
  {
    return this._airtimeCreditsLabel;
  }

  get isNew(): boolean
  {
    return this._isNew;
  }
  set isNew(value: boolean)
  {
    this._isNew = value;
  }

  get currentStationId(): number
  {
    return this._currentStationId;
  }
  set currentStationId(value: number)
  {
    if (value && value > 0 && this._currentStationId != value)
    {
      this._projectManagersForStation = [];
      this._adOpsForStation = [];

      this._currentStationId = value;

      this._projectManagersForStation = this.commonDataService.projectManagers().filter((u: ContactTypeUser) => u.StationIds.some(s => s == this._currentStationId));

      this._adOpsForStation = this.commonDataService.adOps().filter((u: ContactTypeUser) => u.StationIds.some(s => s == this._currentStationId));
    }
  }

  get projectManagersForStation(): ContactTypeUser[]
  {
    return this._projectManagersForStation;
  }

  get adOpsForStation(): ContactTypeUser[]
  {
    return this._adOpsForStation;
  }

  equalsPT(o1: PropertyType, o2: PropertyType)
  {
    return o1 && o2 && o1.Id === o2.Id;
  }

  setExclusionDatesForStation()
  {
    this.currentExclusionDates = [];

    const psi: PropertyStationInfo = this.formSSI.value;

    if (psi)
    {
      const peds: PropertyExclusionDate[] = psi.ExclusionDates;

      if (peds && peds.length > 0)
      {
        peds.map((ex: PropertyExclusionDate) =>
        {
          this.currentExclusionDates.push(new Date(ex.ExclusionDate));
        });
      }
    }
  }

  dateClicked(event: any)
  {
    if (event)
    {
      this.currentExclusionDates = [];

      if (event.value)
      {
        this.currentExclusionDates = event.value;
      }

      this.formSSI.markAsDirty();
      this.form.markAsDirty();
    }
  }



  ngOnDestroy()
  {
    // prevent memory leak when component destroyed
    if (this.dialogCloseSubscription)
    {
      this.dialogCloseSubscription.unsubscribe();
    }
  }

  onCancelClick(): void
  {
    this.dialogRef.close();
  }

  updateFormSSIValuesToFormPropertyStationInfos(psis: PropertyStationInfo[] = [])
  {
    if (this.formSSI.dirty)
    {
      //Get previous PropertyStationInfo filled with any changed values
      const previousPsi: PropertyStationInfo = this.formSSI.value;

      if (previousPsi)
      {
        if (!previousPsi.StationId)
        {
          previousPsi.StationId = this.currentStationId;
        }

        if (this.currentProjectManager)
        {
          //Update project manager manually as this control is bound to a variable not form control.
          previousPsi.ProjectManagerId = this.currentProjectManager.Id;
        }

        if (this.currentAdOps)
        {
          //Update ad ops manually as this control is bound to a variable not form control.
          previousPsi.AdOpsId = this.currentAdOps.Id;
        }

        previousPsi.ExclusionDates = [];

        if (this.currentExclusionDates && this.currentExclusionDates.length > 0)
        {
          this.currentExclusionDates.map((d: Date) =>
          {
            const newPed: PropertyExclusionDate = new PropertyExclusionDate();
            newPed.ChangedByUserId = 0;
            newPed.ExclusionDate = d;
            newPed.PropertyId = previousPsi.PropertyId;
            newPed.StationId = previousPsi.StationId;
            newPed.LastUpdated = this.commonService.today;

            previousPsi.ExclusionDates.push(newPed);
          });
        }

        if (!psis || psis.length == 0)
        {
          psis = this.form.get("StationSpecificInfo").value;
        }

        const index = psis.findIndex(a => a.StationId == previousPsi.StationId);

        if (index > -1 && psis.length > index)
        {
          psis[index] = previousPsi;
        }
      }
    }
  }


  async onSave(): Promise<void>
  {
    try
    {
      this.saveInProgress = true;

      const property: Property = this.form.getRawValue();

      if (property)
      {
        if (this.isRadioBroadcastPropertyType())
        {
          property.Pre = false;
          property.Mid = false;
          property.Post = false;
        }

        //update values for current station before save.
        this.updateFormSSIValuesToFormPropertyStationInfos(property.StationSpecificInfo);

        if (property.ClientsProppedPerSpot < 1)
        {
          property.ClientsProppedPerSpot = 1;
        }

        if (property.ClientsSoldPerSpot < 1)
        {
          property.ClientsSoldPerSpot = 1;
        }

        //must convert to service friendly date format
        property.EndDate = this.commonService.formatDateForService(property.EndDate);
        property.StartDate = this.commonService.formatDateForService(property.StartDate);

        //reset Station Levels
        property.StationLevels = [];

        property.StationSpecificInfo.map(psi =>
        {
          psi.OnAirTime = this.commonService.formatDateForService(psi.OnAirTime);

          psi.ExclusionDates.map(ed =>
          {
            ed.ExclusionDate = this.commonService.formatDateForService(ed.ExclusionDate);
          });

          if (psi.ClientsSoldPerSpot < 1)
          {
            psi.ClientsSoldPerSpot = 1;
          }

          if (!psi.Id || psi.Id < 1)
          {
            psi.Id = 0;
          }

          if (!psi.PropertyId || psi.PropertyId < 1)
          {
            psi.PropertyId = 0;
          }

          if (psi.Rate < 1)
          {
            psi.Rate = 0;
          }

          if (psi.MediaValue < 1)
          {
            psi.MediaValue = 0;
          }

          const sl: StationLevel = new StationLevel();
          sl.StationId = psi.StationId;
          sl.MaxLevel = psi.ClientsSoldPerSpot;
          property.StationLevels.push(sl);
        });


        if (!this.isNew && property.Id > 0)
        {
          //Disable AFTER values are set in object or they don't come across
          this.disableFormControls(this.saveInProgress);

          this.updateProperty.mutate(property, {
            onSuccess: (data: any, variables: any, context: any) =>
            {
              this.saveInProgress = false;
              this.disableFormControls(this.formInitiallyDisabled);

              if (data)
              {
                if (data.IsSuccessful)
                {
                  if (data.Message)
                  {
                    this.commonService.notifySuccess("Saved", data.Message);
                  }

                  const oldPropertyIndex: number = this.calendarsService.properties().findIndex(p => p.Id == property.Id);

                  if (oldPropertyIndex > -1)
                  {
                    property.IdAndPropertyName = `${property.PropertyName} - ${property.Id}`;

                    //Update the changes to the property in the property array in the calendar service
                    this.calendarsService.properties()[oldPropertyIndex] = property;
                  }

                  this.dialogRef.close(data);
                }
                else
                {
                  let errMsg = "Unable to save record.";

                  if (data.ErrorMessage)
                  {
                    errMsg = data.ErrorMessage;
                  }

                  this.commonService.notifyFailure("Error", errMsg, data.ExceptionMessage, data.ValidationExceptionMessage);
                }
              }
            },
            onError: (error, variables, context) =>
            {
              this.saveInProgress = false;
              this.disableFormControls(this.formInitiallyDisabled);
              this.loggerService.error(error.message + "  Unable to update property " + property.PropertyName + ".");
            }
          })
        }
        else
        {
          this.createProperty.mutate(property, {
            onSuccess: (data: any, variables: any, context: any) =>
            {
              this.saveInProgress = false;
              this.disableFormControls(this.formInitiallyDisabled);

              if (data)
              {
                if (data.IsSuccessful)
                {
                  if (data.Message)
                  {
                    this.commonService.notifySuccess("Saved", data.Message);
                  }

                  this.dialogRef.close(data);
                }
                else
                {
                  let errMsg = "Unable to save record.";

                  if (data.ErrorMessage)
                  {
                    errMsg = data.ErrorMessage;
                  }

                  this.commonService.notifyFailure("Error", errMsg, data.ExceptionMessage, data.ValidationExceptionMessage);
                }
              }
            },
            onError: (error, variables, context) =>
            {
              this.saveInProgress = false;
              this.disableFormControls(this.formInitiallyDisabled);
              this.loggerService.error(error.message + "  Unable to create property " + property.PropertyName + ".");
            }
          })
        }
      }
      else
      {
        this.dialogRef.close(null);
      }
    }
    catch
    {
      this.saveInProgress = false;
      this.disableFormControls(this.formInitiallyDisabled);
    }
  }

  ensureAtLeastOnPreMidPostSelected(isPre: boolean)
  {
    if (!this.form.get("Pre").value && !this.form.get("Mid").value && !this.form.get("Post").value)
    {
      if (isPre)
      {
        this.form.get("Mid").patchValue(true, { emitEvent: false, onlySelf: true });
      }
      else
      {
        this.form.get("Pre").patchValue(true, { emitEvent: false, onlySelf: true });
      }
    }
  }

  changePre(event: any)
  {
    if (!event.checked)
    {
      this.ensureAtLeastOnPreMidPostSelected(true);
    }

    this.form.markAsDirty();
  }

  changeMid(event: any)
  {
    if (!event.checked)
    {
      this.ensureAtLeastOnPreMidPostSelected(false);
    }

    this.form.markAsDirty();
  }

  changePost(event: any)
  {
    if (!event.checked)
    {
      this.ensureAtLeastOnPreMidPostSelected(false);
    }

    this.form.markAsDirty();
  }

  changeSpotsAvailable(event: any)
  {
    this.formSSI.markAsDirty();
    this.form.markAsDirty();
  }

  changeRate(event: any)
  {
    this.formSSI.markAsDirty();
    this.form.markAsDirty();
  }

  changeMediaValue(event: any)
  {
    this.formSSI.markAsDirty();
    this.form.markAsDirty();
  }

  onAirCrossChanged(event: any)
  {
    this.formSSI.markAsDirty();
    this.form.markAsDirty();
  }

  onAirTimeChange(event: any)
  {
    this.formSSI.markAsDirty();
    this.form.markAsDirty();
  }

  propertyStationChange(event: any)
  {
    const stations: number[] = [];
    stations.push(event);

    this.currentStationId = event;

    this.form.get("Stations").patchValue(stations);

    this.setPropertyStationChanges(stations);
  }

  handlePropertyStationsChange(event: any)
  {
    const stations: number[] = event;

    this.setPropertyStationChanges(stations);
  }

  setPropertyStationChanges(stations: number[])
  {
    this.form.markAsDirty();

    if (stations && stations.length > 0)
    {
      let psis: PropertyStationInfo[] = this.form.get("StationSpecificInfo").value;

      if (psis && psis.length > 0)
      {
        //remove any station specific info for any stations that are not selected in the property's station
        psis = psis.filter((s: PropertyStationInfo) =>
        {
          return stations.some((stationId: number) => stationId == s.StationId);
        });
      }

      stations.map((stationId: number) =>
      {
        //add new blank station specific info object for station if one doesn't exist.
        if (!psis || !psis.some((ssi: PropertyStationInfo) => stationId == ssi.StationId))
        {
          const newSSI: PropertyStationInfo = new PropertyStationInfo();
          newSSI.StationId = stationId;
          newSSI.PropertyId = this.propertyId();

          if (!psis)
          {
            psis = [];
          }

          this.commonDataService.dayparts().map(dp =>
          {
            const pci: PropertyCreditInfo = new PropertyCreditInfo();
            pci.DayPartId = dp.Id;
            pci.DayPartLabel = dp.DayPartLabel;
            pci.Mon = 0;
            pci.Tue = 0;
            pci.Wed = 0;
            pci.Thu = 0;
            pci.Fri = 0;
            pci.Sat = 0;
            pci.Sun = 0;
            pci.PropertyId = this.propertyId();
            pci.StationId = stationId;

            newSSI.PropertyCreditInfos.push(pci);
          });

          psis.push(newSSI);
        }
      });

      this.form.get("StationSpecificInfo").patchValue(psis, { emitEvent: false });

      if (psis && psis.length > 0 && (!this.formSSI.get("Id").value || !this.isRadioBroadcastPropertyType()))
      {
        this.formSSI.patchValue(psis[0], { emitEvent: false });
      }

      this.setPropertyStations();

      //set currentStationId to first station if currentStationId is not set or it is not in propertyStations
      if (this.currentStationId == null || this.currentStationId < 1 || (this.propertyStations && this.propertyStations.length > 0 && !this.propertyStations.some(s => s.Id == this.currentStationId)))
      {
        this.currentStationId = this.propertyStations[0].Id;

        this.setSelectedStationsDetails(this.currentStationId);
      }

      if (!this.isRadioBroadcastPropertyType())
      {
        this.setSelectedStationsDetails(this.currentStationId);
      }
    }

    //Show required fields in red
    this.formSSI.markAllAsTouched();
  }


  setSelectedStationsDetails(stationId: number, setExclusionDates = false)
  {
    const station: Station = this.propertyStations.filter((station: Station) => station.Id == stationId)[0];

    if (station)
    {
      this.currentStationContact = station.Contact;
      this.currentStationContactEmail = station.ContactEmail;
      this.currentStationContactPhoneNo = station.ContactPhoneNo;
    }

    const psis: PropertyStationInfo[] = this.form.get("StationSpecificInfo").value;

    this.updateFormSSIValuesToFormPropertyStationInfos(psis);

    if (psis && psis.some((f: any) => f.StationId == stationId))
    {
      const psi: PropertyStationInfo = psis.filter((f: any) => f.StationId == stationId)[0];

      if (psi)
      {
        this.currentProjectManager = null;
        this.currentAdOps = null;

        if (this.projectManagersForStation && this.projectManagersForStation.length > 0)
        {
          this.currentProjectManager = this.projectManagersForStation.filter((pm: ContactTypeUser) => pm.Id == psi.ProjectManagerId)[0];
        }

        if (this.adOpsForStation && this.adOpsForStation.length > 0)
        {
          this.currentAdOps = this.adOpsForStation.filter((ao: ContactTypeUser) => ao.Id == psi.AdOpsId)[0];
        }

        this.formSSI.patchValue(psi);

        if (setExclusionDates)
        {
          //Set new exclusion dates for station after form is patched with new data.
          this.setExclusionDatesForStation();
        }

        this.cdr.detectChanges();
      }
    }
  }

  get showOnAirCross(): boolean
  {
    let show = false;

    show = this.form.get('PropertyType').value?.PropertyTypeName == "Street";

    return show;
  }

  get showOnAirTime(): boolean
  {
    let show = false;

    show = this.form.get('PropertyType').value?.PropertyTypeName == "Street";

    return show;
  }

  get showRateCard(): boolean
  {
    let show = true;

    show = !this.isNew && this.formSSI.get('PropertyId').value > 0 && this.formSSI.get('StationId').value > 0;

    return show;
  }

  trackByStations(index: number, station: Station)
  {
    return station["Id"];
  }

  trackByCategories(index: number, category: Category)
  {
    return category["Id"];
  }

  trackByPropertyTypes(index: number, propertyType: PropertyType)
  {
    return propertyType["Id"];
  }

}


