import { CdkDrag, CdkDragHandle } from "@angular/cdk/drag-drop";
import { Component, Inject, WritableSignal, effect, inject, signal } from "@angular/core";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { injectMutation, injectQuery, QueryClient } from "@tanstack/angular-query-experimental";
import { Subscription } from 'rxjs';
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import { CommonService } from '../../../shared/services/common.service';
import { ConfigurationService } from "../../../shared/services/configuration.service";
import { DataHandlerService } from "../../../shared/services/data-handler.service";
import { LoggerService } from "../../../shared/services/logger.service";
import { Category } from '../calendars.models';
import { CalendarsService } from '../calendars.service';


@Component({
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  standalone: true,
  imports: [MatProgressSpinnerModule, CdkDrag, FormsModule, ReactiveFormsModule, CdkDragHandle, MatDialogTitle, MatFormFieldModule, MatInputModule, MatCheckboxModule, MatDialogActions, MatButtonModule]
})
export class CategoryComponent 
{
  public form: FormGroup = new FormGroup({
    Id: new FormControl(),
    CategoryName: new FormControl(null, [Validators.required, Validators.pattern(/[\S]/g)]),
    HasOTP: new FormControl(),
    ShowInWhatsOn: new FormControl(),
    HasPageBackground: new FormControl(),
    Disabled: new FormControl(),
    SortOrder: new FormControl()
  });
  public saveInProgress = false;
  public formInitiallyDisabled = false;

  public categoryName = "";

  private _isNew = false;
  private isDisabledValueChangesSubscription: Subscription;

  private queryClient = new QueryClient();

  private dataHandler = inject(DataHandlerService);
  public configurationService = inject(ConfigurationService);

  private categoryId: WritableSignal<number> = signal(0);

  public updateCategory = injectMutation(() => ({
    mutationFn: (category: any) =>
    {
      return this.calendarsService.updateCategory(category);
    },
    onSuccess: (data: any, variables: any, context: any) =>
    {
      if (this.data > 0)
      {
        this.queryClient.invalidateQueries({
          type: "all", exact: false, predicate: (query: any) =>
          {
            return query.queryKey[0] == "Category" && (query.queryKey[1] == null || query.queryKey[1] == variables.Id || query.queryKey[1] == 0);
          }
        });

        this.queryClient.invalidateQueries({
          type: "all", exact: false, predicate: (query: any) =>
          {
            return query.queryKey[0] == "Categories";
          }
        });

        this.queryClient.invalidateQueries({
          type: "all", exact: false, predicate: (query: any) =>
          {
            return query.queryKey[0] == "Calendar";
          }
        });
      }
    }
  }))

  public createCategory = injectMutation(() => ({
    mutationFn: (category: any) =>
    {
      return this.calendarsService.createCategory(category);
    },
    onSuccess: (data: any, variables: any) =>
    {
      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "Calendar";
        }
      });

      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "Categories";
        }
      });

      this.queryClient.prefetchQuery({
        queryKey: ["Category", data.Id],
        queryFn: () => this.calendarsService.getCategory(data.Id),
        //staleTime: this.configurationService.cbSettings().prefetchQueryStaleTime
      });
    }
  }))

  public deleteCategory = injectMutation(() => ({
    mutationFn: (category: any) =>
    {
      return this.calendarsService.deleteCategory(category);
    },
    onSuccess: (data: any, variables: any) =>
    {
      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "Category" && (query.queryKey[1] == null || query.queryKey[1] == variables.Id || query.queryKey[1] == 0);
        }
      });

      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "Categories";
        }
      });

      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "Calendar";
        }
      });
    }
  }))


  public getCategory = injectQuery(() =>
  ({
    enabled: this.configurationService.cbSettings().calendarServiceUrl.length > 0 && this.categoryId() != null && this.categoryId() > 0,
    queryKey: ["Category", this.categoryId()],
    queryFn: () => this.calendarsService.getCategory(this.categoryId()),
    //staleTime: this.configurationService.cbSettings().queryStaleTime,
  }))

  public categoryChanged = effect(() =>
  {
    const result: any = this.getCategory.data();

    if (result && result.Id)
    {
      this.categoryName = result.CategoryName;

      this.form.controls["CategoryName"].patchValue(result.CategoryName, { emitEvent: false });
      this.form.controls["HasOTP"].patchValue(result.HasOTP, { emitEvent: false });
      this.form.controls["HasPageBackground"].patchValue(result.HasPageBackground, { emitEvent: false });
      this.form.controls["Disabled"].patchValue(result.Disabled, { emitEvent: false });
      this.form.controls["SortOrder"].patchValue(result.SortOrder, { emitEvent: false });
    }
  });


  constructor(
    private authorisationService: AuthorisationService,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CategoryComponent>, @Inject(MAT_DIALOG_DATA)
    public data: number, private commonService: CommonService,
    private calendarsService: CalendarsService,
    private loggerService: LoggerService)
  {
    this.form = fb.group({
      Id: [data, Validators.required],
      CategoryName: ["New Category", Validators.required],
      HasOTP: [false],
      HasPageBackground: [false],
      Disabled: [false],
      SortOrder: [0, Validators.compose([Validators.required, Validators.pattern('^\\d+$')])]
    });


    this.isDisabledValueChangesSubscription = this.form.get('Disabled').valueChanges.subscribe(val =>
    {
      this.disableFormControls(val);
    });
  }


  ngOnInit(): void
  {
    this.isNew = this.data < 1;

    if (this.data)
    {
      this.categoryId.set(this.data);
    }

    if (this.isNew)
    {
      const category: Category = new Category();
      category.CategoryName = "New Category";
      category.Id = 0;
      category.SortOrder = 0;
      category.Disabled = false;

      this.categoryName = category.CategoryName;

      this.categoryId.set(category.Id);
    }


    this.formInitiallyDisabled = (this.isNew && !this.authorisationService.hasPermission(this.authorisationService.Permissions.AddCategory)) || (!this.isNew && !this.authorisationService.hasPermission(this.authorisationService.Permissions.EditCategory));
    this.disableFormControls(this.formInitiallyDisabled);
  }

  disableFormControls(disable: boolean)
  {
    if (disable)
    {
      this.form.get('CategoryName').disable({ emitEvent: false });
      this.form.get('HasOTP').disable({ emitEvent: false });
      this.form.get('HasPageBackground').disable({ emitEvent: false });
      this.form.get('SortOrder').disable({ emitEvent: false });
    }
    else
    {
      this.form.get('CategoryName').enable({ emitEvent: false });
      this.form.get('HasOTP').enable({ emitEvent: false });
      this.form.get('HasPageBackground').enable({ emitEvent: false });
      this.form.get('SortOrder').enable({ emitEvent: false });
    }
  }


  get isNew(): boolean
  {
    return this._isNew;
  }
  set isNew(value: boolean)
  {
    this._isNew = value;
  }


  ngOnDestroy()
  {
    // prevent memory leak when component destroyed
    if (this.isDisabledValueChangesSubscription)
    {
      this.isDisabledValueChangesSubscription.unsubscribe();
    }
  }

  onCancelClick(): void
  {
    this.dialogRef.close();
  }

  async onSave(): Promise<void>
  {
    try
    {
      this.saveInProgress = true;

      const category: Category = this.form.value;

      //Disable AFTER values are set in object or they don't come across
      this.disableFormControls(this.saveInProgress);

      if (category)
      {
        if (!this.isNew || category.Id > 0)
        {
          this.updateCategory.mutate(category, {
            onSuccess: (data: any, variables: any, context: any) =>
            {
              this.saveInProgress = false;
              this.disableFormControls(this.formInitiallyDisabled);

              if (data)
              {
                if (data.IsSuccessful)
                {
                  if (data.Message)
                  {
                    this.commonService.notifySuccess("Saved", data.Message);
                  }

                  const oldCategoryIndex: number = this.calendarsService.categories().findIndex(c => c.Id == category.Id);

                  if (oldCategoryIndex > -1)
                  {
                    //Update the changes to the category in the category array in the calendar service
                    this.calendarsService.categories()[oldCategoryIndex] = category;
                  }

                  this.dialogRef.close(data);
                }
                else
                {
                  let errMsg = "Unable to save record.";

                  if (data.ErrorMessage)
                  {
                    errMsg = data.ErrorMessage;
                  }

                  this.commonService.notifyFailure("Error", errMsg, data.ExceptionMessage, data.ValidationExceptionMessage);
                }
              }
            },
            onError: (error, variables, context) =>
            {
              this.saveInProgress = false;
              this.disableFormControls(this.formInitiallyDisabled);
              this.loggerService.error(error.message + "  Unable to update category " + category.CategoryName + ".");
            }
          })
        }
        else
        {
          this.createCategory.mutate(category, {
            onSuccess: (data: any, variables: any, context: any) =>
            {
              this.saveInProgress = false;
              this.disableFormControls(this.formInitiallyDisabled);

              if (data)
              {
                if (data.IsSuccessful)
                {
                  if (data.Message)
                  {
                    this.commonService.notifySuccess("Saved", data.Message);
                  }

                  category.Id = parseInt(data.Id);

                  this.dialogRef.close(data);
                }
                else
                {
                  let errMsg = "Unable to save record.";

                  if (data.ErrorMessage)
                  {
                    errMsg = data.ErrorMessage;
                  }

                  this.commonService.notifyFailure("Error", errMsg, data.ExceptionMessage, data.ValidationExceptionMessage);
                }
              }
            },
            onError: (error, variables, context) =>
            {
              this.saveInProgress = false;
              this.disableFormControls(this.formInitiallyDisabled);
              this.loggerService.error(error.message + "  Unable to create category " + category.CategoryName + ".");
            }
          })
        }
      }
      else
      {
        this.dialogRef.close(null);
      }
    }
    catch
    {
      this.saveInProgress = false;
      this.disableFormControls(this.formInitiallyDisabled);
    }
  }



}



