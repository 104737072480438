<div id="diary" class="diary-control">
  <div class="diary-filter">
    @if (isPrinting) {
      <label style="padding: 0 25px 10px 5px; font-weight:bold; white-space: nowrap; display:inline-block">{{selectedDatesLabel}}</label>
    }
    <label style="padding: 0 10px;white-space: nowrap;">Platform Group</label>
    <select class="select-dropdown" (change)="handleStationGroupChange($event)" [disabled]="calendarsService.stationGroups()===[]">
      <option [value]="0" [selected]="true">All</option>
      @for (stationGroup of calendarsService.stationGroups(); track stationGroup.Id) {
        <option [value]="stationGroup.Id" [selected]="stationGroup.Id===diaryService.selectedStationGroupId">{{stationGroup.StationTypeName}}</option>
      }
    </select>
    <label style="padding: 0px 10px 0 35px; white-space: nowrap;">Platform Providers</label>
    <kendo-multiselect [autoClose]="false" #dstations [data]="diaryService.availableStations"
      [textField]="'StationName'"
      [valueField]="'Id'"
      [valuePrimitive]="false"
      [size]="'small'"
      [fillMode]="'flat'"
      [(ngModel)]="diaryService.selectedStations"
      placeholder="Select Platform Provider(s)"
      [kendoDropDownFilter]="filterSettings"
      (valueChange)="dStationsSelectedChange($event);"
      [disabled]="diaryService.availableStations===[]">
    </kendo-multiselect>
    <div class="diary-form-group noExport" style="margin: .1em .75em; width:1em; align-self: center;">
      <button class="k-button k-bare" title="Print calendar" (click)="print()" [disabled]="isPrinting" style="border: none">
        <fa-icon [icon]="faPrint"></fa-icon>
      </button>
    </div>
  </div>
  <kendo-scheduler [kendoSchedulerBinding]="diaryService.events | async"
    [modelFields]="diaryService.fields"
    [currentTimeMarker]="currentTimezoneSettings"
    [timezone]="currentTimeZone"
    [resources]="resources"
    [loading]="diaryService.loading"
    [editable]="true"
    [selectedDate]="selectedDate"
    (slotDblClick)="slotDblClickHandler($event)"
    (eventDblClick)="eventDblClickHandler($event)"
    (dateChange)="onDateChange($event)"
    (remove)="removeHandler($event)"
    [(selectedViewIndex)]="diaryService.selectedViewIndex"
    [eventClass]="getEventClass"
    style="height: max-content;">

    <ng-template kendoSchedulerToolbarTemplate>
      <div style="position: absolute">
        <ul class="noExport" kendoSchedulerToolbarNavigation></ul>
        <ul kendoSchedulerToolbarViewSelector></ul>
      </div>
      <div kendoSchedulerToolbarViewSelector
        [toolbarWidth]="200"
        [responsiveBreakpoint]="960">
      </div>
    </ng-template>

    <ng-template kendoSchedulerEventTemplate let-event="event">
      <div title="{{ event.dataItem.Header }} {{ event?.dataItem?.Description }}">{{ event?.dataItem?.Header }}</div>
    </ng-template>
    <ng-template kendoSchedulerAllDayEventTemplate let-event="event">
      <div title="{{ event.dataItem.Header }} {{ event?.dataItem?.Description }}">{{ event?.dataItem?.Header }}</div>
    </ng-template>

    <kendo-scheduler-day-view>
    </kendo-scheduler-day-view>

    <kendo-scheduler-month-view>
    </kendo-scheduler-month-view>

    <kendo-scheduler-pdf fileName="WhatsOn.pdf">
    </kendo-scheduler-pdf>
  </kendo-scheduler>

  @if (diaryService.isEditing) {
    <kendo-dialog (close)="closeForm()">
      <kendo-dialog-titlebar>
        {{ isNew ? 'Add What\'s On Event' : 'Edit What\'s On Event' }}
      </kendo-dialog-titlebar>
      <event-edit-form [event]="editedEvent"
        [editMode]="editMode"
        [isNew]="isNew"
        (save)="saveHandler($event)">
      </event-edit-form>
    </kendo-dialog>
  }
</div>
