import { CdkDrag, CdkDragHandle } from "@angular/cdk/drag-drop";
import { Component, Inject, Input, OnDestroy, OnInit, Optional } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerInputEvent, MatDatepickerModule } from "@angular/material/datepicker";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogRef, MatDialogTitle } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { ComboBoxModule, DropDownFilterSettings, MultiSelectModule, DropDownListModule } from "@progress/kendo-angular-dropdowns";
import { FloatingLabelModule } from "@progress/kendo-angular-label";
import { CommonService } from "../../shared/services/common.service";
import { CalendarsService } from "../calendars/calendars.service";
import { ReportService } from "./report.service";
import { ReportParameters } from "./reports.models";
import { WeekListItem } from "../calendars/calendars.models";
import { addDays, differenceInCalendarDays, format, parse } from "date-fns";


@Component({
  selector: "report-parameters",
  templateUrl: './report-parameters.component.html',
  styleUrls: ['./report.component.scss'],
  providers: [],
  standalone: true,
  imports: [CdkDrag, FormsModule, ReactiveFormsModule, DropDownListModule, CdkDragHandle, MatDialogTitle, MatFormFieldModule, MatInputModule, MatDatepickerModule, FloatingLabelModule, MultiSelectModule, ComboBoxModule, MatDialogActions, MatButtonModule]
})
export class ReportParametersComponent implements OnInit, OnDestroy
{
  public form: UntypedFormGroup;

  private _weeksWC: WeekListItem[] = [];
  private _weeksWE: WeekListItem[] = [];

  public endDateWLI: WeekListItem = null;
  public startDateWLI: WeekListItem = null;

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  @Input() public dragDisabled = true;

  constructor(
    public fb: UntypedFormBuilder,
    public calendarsService: CalendarsService,
    public reportService: ReportService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) @Optional() private data: ReportParameters = null,
    @Optional() private dialogRef: MatDialogRef<ReportParametersComponent> = null
  )
  {
    this.form = this.fb.group({
      ReportName: [this.data.ReportName],
      ReportTitle: [this.data.ReportTitle],
      StartDate: [],
      EndDate: [],
      StationIds: [this.data.StationIds],
      PropertyTypeIds: [this.data.PropertyTypeIds],
      CategoryIds: [this.data.CategoryIds],
      PropertyIds: [this.data.PropertyIds],
      Statuses: [this.data.Statuses],
      CategoryId: [this.data.CategoryId],
      PropertyId: [this.data.PropertyId]
    });
  }


  ngOnInit(): void
  {
    if (this.data)
    {
      if (this.data.StartDate)
      {
        this.data.StartDate = this.commonService.parseJsonDate(this.data.StartDate);
      }

      if (this.data.EndDate)
      {
        this.data.EndDate = this.commonService.parseJsonDate(this.data.EndDate);
      }

      //this.form.controls["StartDate"].patchValue(this.data.StartDate, { emitEvent: false });
      this.form.controls["EndDate"].patchValue(this.data.EndDate, { emitEvent: false });

      if (this.showWeekStartDate || this.showWeekEndDate)
      {
        this._weeksWC = this.calendarsService.weeks().filter(w => w.WeekDate >= this.commonService.today);

        this._weeksWE = JSON.parse(JSON.stringify(this._weeksWC));

        this._weeksWE.map((w: WeekListItem) =>
        {
          w.WeekDate = addDays(w.WeekDate, 6);
          w.Name = format(w.WeekDate, "d MMMM yyyy");
          w.WeekDateString = format(w.WeekDate, "yyyy-MM-d");
        });

        if (this.weeksWC.length > 0)
        {
          this.startDateWLI = this.weeksWC.filter(w => differenceInCalendarDays(w.WeekDate, this.data.StartDate) == 0)[0];

          if (!this.startDateWLI)
          {
            this.startDateWLI = this.weeksWC[0];
          }

          this.form.controls["StartDate"].patchValue(this.startDateWLI.WeekDate, { emitEvent: false });
        }

        if (this.weeksWE.length > 0)
        {
          this.endDateWLI = this.weeksWE.filter(w => differenceInCalendarDays(w.WeekDate, this.data.EndDate) == 0)[0];

          if (!this.endDateWLI)
          {
            this.endDateWLI = this.weeksWE[0];
          }

          this.form.controls["EndDate"].patchValue(this.endDateWLI.WeekDate, { emitEvent: false });
        }
      }
    }
  }

  startDateWliChange(week: WeekListItem)
  {
    this.form.controls["StartDate"].patchValue(week.WeekDate, { emitEvent: false });

    if (this.startDateWLI && this.endDateWLI && (this.startDateWLI.Year > this.endDateWLI.Year || (this.startDateWLI.WeekNo > this.endDateWLI.WeekNo && this.startDateWLI.Year == this.endDateWLI.Year)))
    {
      //Ensure StartDate is not greater than EndDate
      this.endDateWLI = this.weeksWE.filter(w => w.WeekNo == this.startDateWLI.WeekNo && w.Year == this.startDateWLI.Year)[0];

      this.form.controls["EndDate"].patchValue(this.endDateWLI.WeekDate, { emitEvent: false });
    }

    this.form.markAsDirty();
  }

  endDateWliChange(week: WeekListItem)
  {
    this.form.controls["EndDate"].patchValue(week.WeekDate, { emitEvent: false });

    if (this.startDateWLI && this.endDateWLI && (this.startDateWLI.Year > this.endDateWLI.Year || (this.startDateWLI.WeekNo > this.endDateWLI.WeekNo && this.startDateWLI.Year == this.endDateWLI.Year)))
    {
      //Ensure StartDate is not greater than EndDate
      this.startDateWLI = this.weeksWC.filter(w => w.WeekNo == this.endDateWLI.WeekNo && w.Year == this.endDateWLI.Year)[0];

      this.form.controls["StartDate"].patchValue(this.startDateWLI.WeekDate, { emitEvent: false });
    }

    this.form.markAsDirty();
  }

  startDateChange(event: MatDatepickerInputEvent<Date>)
  {
    if (this.form.get("StartDate").value && this.form.get("EndDate").value && this.form.get("StartDate").value > this.form.get("EndDate").value)
    {
      //Ensure StartDate is not greater than EndDate
      this.form.controls["EndDate"].patchValue(this.form.get("StartDate").value, { emitEvent: false });
    }
  }

  endDateChange(event: MatDatepickerInputEvent<Date>)
  {
    if (this.form.get("StartDate").value && this.form.get("EndDate").value && this.form.get("StartDate").value > this.form.get("EndDate").value)
    {
      //Ensure StartDate is not greater than EndDate
      this.form.controls["StartDate"].patchValue(this.form.get("EndDate").value, { emitEvent: false });
    }
  }

  get weeksWC(): WeekListItem[]
  {
    return this._weeksWC;
  }

  get weeksWE(): WeekListItem[]
  {
    return this._weeksWE;
  }

  onCancelClick(): void
  {
    this.dialogRef.close();
  }

  onSave(): void
  {
    const params: ReportParameters = this.form.value;

    if (!this.showCategoryId)
    {
      params.CategoryId = 0;
    }
    if (!this.showCategoryIds)
    {
      params.CategoryIds = [];
    }
    if (!this.showEndDate && !this.showWeekEndDate)
    {
      params.EndDate = null;
    }
    if (!this.showPropertyId)
    {
      params.PropertyId = 0;
    }
    if (!this.showPropertyIds)
    {
      params.PropertyIds = [];
    }
    if (!this.showPropertyTypeIds)
    {
      params.PropertyTypeIds = [];
    }
    if (!this.showStartDate && !this.showWeekStartDate)
    {
      params.StartDate = null;
    }
    if (!this.showStationIds)
    {
      params.StationIds = [];
    }
    if (!this.showStatuses)
    {
      params.Statuses = [];
    }

    this.dialogRef.close(params);
  }

  ngOnDestroy()
  {

  }


  get showStartDate(): boolean
  {
    let show = false;

    //show = this.data.ReportName == "CreditLine.trdp"
    //  || this.data.ReportName == "CreditLineFollowUps.trdp"
    //  || this.data.ReportName == "ActivitiesForProperty.trdp"
    //  || this.data.ReportName == "Fill.trdp"
    //  || this.data.ReportName == "CreditsReport.trdp"
    //  || this.data.ReportName == "PropertyRevenue.trdp";

    return show;
  }

  get showEndDate(): boolean
  {
    let show = false;

    //show = this.data.ReportName == "CreditLine.trdp"
    //  || this.data.ReportName == "CreditLineFollowUps.trdp"
    //  || this.data.ReportName == "ActivitiesForProperty.trdp"
    //  || this.data.ReportName == "Fill.trdp"
    //  || this.data.ReportName == "CreditsReport.trdp"
    //  || this.data.ReportName == "PropertyRevenue.trdp";

    return show;
  }

  get showWeekStartDate(): boolean
  {
    let show = false;

    //show = this.data.ReportName == "AvailsSummary.trdp";

    return show;
  }

  get showWeekEndDate(): boolean
  {
    let show = false;

    // show = this.data.ReportName == "AvailsSummary.trdp";

    return show;
  }

  get showPropertyTypeIds(): boolean
  {
    let show = true;

    show = this.data.ReportName == "CreditLine.trdp"
      || this.data.ReportName == "CreditLineFollowUps.trdp"
      || this.data.ReportName == "AvailsSummary.trdp"
      || this.data.ReportName == "AvailsDetail.trdp"
      || this.data.ReportName == "Fill.trdp";

    return show;
  }

  get showStationIds(): boolean
  {
    let show = true;

    show = this.data.ReportName == "CreditLine.trdp"
      || this.data.ReportName == "CreditLineFollowUps.trdp"
      || this.data.ReportName == "AvailsSummary.trdp"
      || this.data.ReportName == "AvailsDetail.trdp"
      || this.data.ReportName == "ActivitiesForProperty.trdp"
      || this.data.ReportName == "Fill.trdp"
      || this.data.ReportName == "CreditsReport.trdp"
      || this.data.ReportName == "PropertyRevenue.trdp";

    return show;
  }

  get showCategoryIds(): boolean
  {
    let show = true;

    show = this.data.ReportName == "AvailsSummary.trdp"
      || this.data.ReportName == "AvailsDetail.trdp"
      || this.data.ReportName == "Fill.trdp";

    return show;
  }

  get showPropertyIds(): boolean
  {
    let show = true;

    show = this.data.ReportName == "AvailsSummary.trdp"
      || this.data.ReportName == "AvailsDetail.trdp";

    return show;
  }

  get showStatuses(): boolean
  {
    let show = true;

    show = this.data.ReportName == "ActivitiesForProperty.trdp"
      || this.data.ReportName == "Fill.trdp";

    return show;
  }

  get showCategoryId(): boolean
  {
    let show = true;

    show = this.data.ReportName == "ActivitiesForProperty.trdp"
      || this.data.ReportName == "CreditsReport.trdp"
      || this.data.ReportName == "PropertyRevenue.trdp";

    return show;
  }

  get showPropertyId(): boolean
  {
    let show = true;

    show = this.data.ReportName == "ActivitiesForProperty.trdp"
      || this.data.ReportName == "CreditsReport.trdp"
      || this.data.ReportName == "PropertyRevenue.trdp";

    return show;
  }

}


