import { Component, EventEmitter, Inject, Injector, Input, Signal, WritableSignal, computed, inject, output, signal } from '@angular/core';
import { CalendarsBaseService } from '../calendars-base.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AuthorisationService } from '../../../shared/services/authorisation.service';
import { Activity, ActivityStationInfo, CalendarActivity, Category, ProductCategory, Property, PropertyType, Recurrence, Station } from '../calendars.models';
import { arrowRotateCwIcon, nonRecurrenceIcon } from '@progress/kendo-svg-icons';
import { ComboBoxModule, DropDownFilterSettings, MultiSelectModule, PopupSettings as ddlPopupSettings } from '@progress/kendo-angular-dropdowns';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { injectMutation, QueryClient } from '@tanstack/angular-query-experimental';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FloatingLabelModule, LabelModule } from '@progress/kendo-angular-label';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { FormFieldModule } from '@progress/kendo-angular-inputs';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivityService } from '../activity/activity.service';
import { CalendarsService } from '../calendars.service';
import { addBusinessDays, addDays, differenceInCalendarDays, endOfWeek, startOfWeek } from 'date-fns';
import { CommonService } from '../../../shared/services/common.service';
import { ConfirmationDialog } from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { Subscription, takeUntil } from 'rxjs';
import { LoggerService } from '../../../shared/services/logger.service';
import { BaseComponent } from '../../../shared/components/base/base.component';
import { RecurrenceComponent } from '../../../shared/components/recurrence/recurrence.component';
import { ActivitiesService } from '../activities/activities.service';
import { MatCardModule } from '@angular/material/card';
import '../../../shared/extensions/string.extensions';

@Component({
  selector: 'activity-blocker',
  standalone: true,
  providers: [ActivityService, ActivitiesService],
  imports: [TooltipModule, CdkDrag, FormsModule, ReactiveFormsModule, MatCardModule, CdkDragHandle, MatFormFieldModule, MatInputModule, FloatingLabelModule, ComboBoxModule, MatDatepickerModule, MatIconModule, MatButtonModule, SVGIconModule, MultiSelectModule, FormFieldModule, LabelModule, MatProgressSpinnerModule, MatDialogActions],
  templateUrl: './activity-blocker.component.html',
  styleUrl: './activity-blocker.component.scss'
})
export class ActivityBlockerComponent
{
  public calendarsService: CalendarsBaseService;

  @Input() public showHeader = true;
  @Input() public showFooter = true;
  @Input() public showCancelButton = true;
  @Input() public closeAfterSave = true;
  @Input() public activityData: Activity = null;

  private dialogIsOpen = false;

  public RecurrenceTypeNone = 0;
  public RecurrenceTypeDaily = 1;
  public RecurrenceTypeWeekly = 2;
  public RecurrenceTypeMonthly = 3;
  public RecurrenceTypeYearly = 4;

  public processingMessage = "Loading Activity Blocker, please wait...";
  public processingInProgress = true;


  private _propertyStations: Station[] = [];

  private queryClient = new QueryClient();

  public configurationService = inject(ConfigurationService);

  get blockerMinDate(): Date
  {
    if (this.isNew())
    {
      return new Date(new Date().toDateString());
    }
    else
    {
      return this.currentActivity.StartDate;
    }
  }

  public virtual: any = {
    itemHeight: 28,
  };

  public icons = {
    nonRecurrence: nonRecurrenceIcon,
    recurrence: arrowRotateCwIcon
  };

  private _currentActivity: WritableSignal<Activity> = signal(null);

  private _recurrenceSummary = "";
  private _recurrenceTypeName = "";
  private _currentProperty: Property = null;
  private _currentCategory: Category = null;
  private _currentPropertyType: PropertyType = null;
  private _currentActivityId: number = 0;
  private dialogCloseSubscription: Subscription;
  private recurrenceClosedSubscription: Subscription;

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  public comboBoxPopupSettings: ddlPopupSettings = {
    popupClass: 'popup-class-activity'
  };

  public updateActivity = injectMutation(() => ({
    mutationFn: (activity: any) =>
    {
      return this.calendarsService.updateActivity(activity);
    },
    onSuccess: (data: any, variables: any, context: any) =>
    {
      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          if (query.queryKey[0] == "Activity" && (query.queryKey[1] == null || query.queryKey[1] == variables.Id || query.queryKey[1] == 0))
          {
            return true;
          }

          if (query.queryKey[0] == "Activities")
          {
            if (this.currentActivity.ActivitiesParameters && query.queryKey[1])
            {
              let ap = this.currentActivity.ActivitiesParameters;
              let apQ = query.queryKey[1];

              if (ap.level == apQ.level && ap.propertyId == apQ.propertyId)
              {
                return true;
              }
            }
            else if (!query.queryKey[1])
            {
              return true;
            }
          }

          if (query.queryKey[0] == "Calendar")
          {
            return true;
          }

          return false;
        }
      });
    }
  }))

  public createActivity = injectMutation(() => ({
    mutationFn: (activity: any) =>
    {
      return this.calendarsService.createActivity(activity);
    },
    onSuccess: (data: any, variables: any) =>
    {
      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "Calendar";
        }
      });

      this.queryClient.prefetchQuery({
        queryKey: ["Activity", data.Id],
        queryFn: () => this.calendarsService.getActivity(data.Id),
        //staleTime: this.configurationService.cbSettings().prefetchQueryStaleTime
      });
    }
  }))

  public deleteActivity = injectMutation(() => ({
    mutationFn: (activity: any) =>
    {
      return this.calendarsService.deleteActivity(activity);
    },
    onSuccess: (data: any, variables: any) =>
    {
      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "Activity" && (query.queryKey[1] == null || query.queryKey[1] == variables.Id || query.queryKey[1] == 0);
        }
      });

      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "Activities";
        }
      });

      this.queryClient.invalidateQueries({
        type: "all", exact: false, predicate: (query: any) =>
        {
          return query.queryKey[0] == "Calendar";
        }
      });
    }
  }))

  public form: FormGroup = new FormGroup({
    Id: new FormControl(),
    ClientName: new FormControl(null, [Validators.required]),
    Property: new FormControl(),
    Recurrence: new FormControl(),
    Stations: new FormControl(null, [Validators.required, Validators.minLength(1)]),
    StartDate: new FormControl({ value: null, disabled: false }),
    EndDate: new FormControl({ value: null, disabled: false }),
  });



  public cancelButtonClicked = output<any>();
  public activitySaved = output<any>();

  public isNew: WritableSignal<boolean> = signal(false);
  public isRadioBroadcastPropertyType: WritableSignal<boolean> = signal(true);

  public isAuthorised: Signal<boolean> = computed(() =>
  {
    let may = this.isNew();

    if (this.currentActivity != null && !may)
    {
      may = this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewActivityBlocker)
        || this.authorisationService.hasPermission(this.authorisationService.Permissions.AddActivityBlocker)
        || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditActivityBlocker);
    }

    return may;
  });



  constructor(
    protected injector: Injector,
    private authorisationService: AuthorisationService,
    public activityService: ActivityService,
    public activitiesService: ActivitiesService,
    public commonService: CommonService,
    private loggerService: LoggerService,
    private dialogRef: MatDialogRef<ActivityBlockerComponent>,
    @Inject(MAT_DIALOG_DATA) private data: CalendarActivity,
    private confirmDialog: MatDialog,
    private dialog: MatDialog)
  {

  }

  async ngOnInit(): Promise<void>
  {
    this.calendarsService = this.injector.get(CalendarsService);

    //check if activity information was passed in input parameter
    if (this.activityData)
    {
      //update data variable from input parameter
      this.data = JSON.parse(JSON.stringify(this.activityData));
    }

    this.isNew.set(this.data.Id < 1);

    this._currentActivityId = this.data.Id;

    let activity: Activity = null;

    if (this.isNew())
    {
      activity = await this.activitiesService.CreateNewActivity(this.data);
    }
    else
    {
      activity = await this.calendarsService.getActivity(this.data.Id, true);
    }

    activity.IsBlocker = true;
    this._currentActivity.set(activity);
    this.setupActivity(activity);

    this.processingInProgress = false;

    //Show required fields in red
    this.form.markAllAsTouched();
  }

  setupActivity(activity: Activity)
  {
    try
    {
      if (activity)
      {
        /// MUST BE FIRST!!
        if (this.data.PropertyId)
        {
          this.setCurrentProperty(this.data.PropertyId);

          this.currentActivity.Property = this.currentProperty;
        }

        if (activity.StartDate)
        {
          activity.StartDate = this.commonService.parseJsonDate(activity.StartDate);
        }
        else
        {
          activity.StartDate = this.commonService.today;
        }

        if (activity.EndDate)
        {
          activity.EndDate = this.commonService.parseJsonDate(activity.EndDate);
        }
        else
        {
          activity.EndDate = this.commonService.today;
        }

        if (this.isNew())
        {
          //default to full week for new activity blockers
          activity.StartDate = startOfWeek(activity.StartDate, { weekStartsOn: this.configurationService.cbSettings().startOfWeek });
          activity.EndDate = endOfWeek(activity.StartDate, { weekStartsOn: this.configurationService.cbSettings().startOfWeek });
        }

        if (activity.CreationDate)
        {
          activity.CreationDate = this.commonService.parseJsonDate(activity.CreationDate);
        }
        else
        {
          activity.CreationDate = this.commonService.today;
        }

        if (activity.SoldDate)
        {
          activity.SoldDate = this.commonService.parseJsonDate(activity.SoldDate);
        }

        if (!activity.Stations || activity.Stations.length == 0)
        {
          activity.Stations = [];

          if (this.calendarsService.selectedStations && this.calendarsService.selectedStations.length > 0)
          {
            this.calendarsService.selectedStations.map((station: Station) =>
            {
              ////only add station if in property
              if (this.currentProperty?.StationLevels.some(st => st.StationId == station.Id))
              {
                activity.Stations.push(station.Id);
              }
            });
          }
        }

        if (activity.Recurrence)
        {
          if (activity.Recurrence.FirstOccurrenceDate)
          {
            activity.Recurrence.FirstOccurrenceDate = this.commonService.parseJsonDate(activity.Recurrence.FirstOccurrenceDate);
          }
          if (activity.Recurrence.LastOccurrenceDate)
          {
            activity.Recurrence.LastOccurrenceDate = this.commonService.parseJsonDate(activity.Recurrence.LastOccurrenceDate);
          }
          if (activity.Recurrence.MaximumDate)
          {
            activity.Recurrence.MaximumDate = this.commonService.parseJsonDate(activity.Recurrence.MaximumDate);
          }
          if (activity.Recurrence.MinimumDate)
          {
            activity.Recurrence.MinimumDate = this.commonService.parseJsonDate(activity.Recurrence.MinimumDate);
          }
          if (activity.Recurrence.PatternEndDate)
          {
            activity.Recurrence.PatternEndDate = this.commonService.parseJsonDate(activity.Recurrence.PatternEndDate);
          }
          if (activity.Recurrence.PatternStartDate)
          {
            activity.Recurrence.PatternStartDate = this.commonService.parseJsonDate(activity.Recurrence.PatternStartDate);
          }

          if (activity.Recurrence.RecurrenceType == this.RecurrenceTypeNone)
          {
            this.form.get('StartDate').enable({ emitEvent: false });
            this.form.get('EndDate').enable({ emitEvent: false });
          }
          else
          {
            this.form.get('StartDate').disable({ emitEvent: false });
            this.form.get('EndDate').disable({ emitEvent: false });
          }
        }

        if (this.currentProperty)
        {
          if (this.currentProperty?.StationLevels && this.currentProperty?.StationLevels.length > 0)
          {
            const propStations: Station[] = this.calendarsService.stations().filter((station: Station) => this.currentProperty.StationLevels?.some(s => station.Id == s.StationId && s.MaxLevel > this.data.Level));

            if (propStations && propStations.length > 0)
            {
              //remove station info records for stations that are not selected in the activity
              activity.Stations = activity.Stations.filter(s => propStations.some(ps => ps.Id == s));
            }
          }
        }

        this.form.controls["Id"].patchValue(activity.Id, { emitEvent: false });
        this.form.controls["ClientName"].patchValue(activity.ClientName, { emitEvent: false });
        this.form.controls["Recurrence"].patchValue(activity.Recurrence, { emitEvent: false });
        this.form.controls["Stations"].patchValue(activity.Stations); //Control rendered badly if emitEvent:false
        this.form.controls["StartDate"].patchValue(activity.StartDate, { emitEvent: false });
        this.form.controls["EndDate"].patchValue(activity.EndDate, { emitEvent: false });
      }

    }
    catch (ex)
    {
    }
  }

  get currentActivityId(): number
  {
    return this._currentActivityId;
  }

  get currentActivity(): Activity
  {
    let activity: Activity = this.form.getRawValue();

    if (!activity || activity.Id == null)
    {
      activity = this._currentActivity();
    }

    if (activity)
    {
      activity.IsBlocker = true;
    }

    return activity;
  }

  setCurrentProperty(propertyId: number)
  {
    if (propertyId && this.calendarsService.properties() && this.calendarsService.properties().length > 0)
    {
      if (this.calendarsService.properties().some(c => c.Id == propertyId))
      {
        this._currentProperty = this.calendarsService.properties().filter(c => c.Id == propertyId)[0];
      }

      if (this._currentProperty)
      {
        this.setCurrentCategory(this._currentProperty.CategoryId);
        this.setCurrentPropertyType(this._currentProperty.PropertyType?.Id);
        this.setPropertyStations();

        if (this._currentProperty.ProductCategories && this._currentProperty.ProductCategories.length > 0)
        {
          this._currentProperty.ProductCategories.map((pc: ProductCategory) =>
          {
            const index: number = this.activityService.productCategories.findIndex((prodCat: ProductCategory) => pc.Id == prodCat.Id);

            if (index > -1)
            {
              this.activityService.productCategories[index].HasWarning = true;
            }
          });
        }
      }
    }
  }

  setCurrentCategory(categoryId: number)
  {
    if (categoryId && this.calendarsService.categories() && this.calendarsService.categories().length > 0)
    {
      if (this.calendarsService.categories().some(c => c.Id == categoryId))
      {
        this._currentCategory = this.calendarsService.categories().filter(c => c.Id == categoryId)[0];
      }
    }
  }

  setCurrentPropertyType(propertyTypeId: number)
  {
    if (propertyTypeId && this.calendarsService.propertyTypes() && this.calendarsService.propertyTypes().length > 0)
    {
      if (this.calendarsService.propertyTypes().some(c => c.Id == propertyTypeId))
      {
        this._currentPropertyType = this.calendarsService.propertyTypes().filter(c => c.Id == propertyTypeId)[0];

        if (this._currentPropertyType)
        {
          this.isRadioBroadcastPropertyType.set(this.currentPropertyType.IsBroadcast);
        }
      }
    }
  }

  get currentProperty(): Property
  {
    return this._currentProperty;
  }

  get currentCategory(): Category
  {
    return this._currentCategory;
  }

  get currentPropertyType(): PropertyType
  {
    return this._currentPropertyType;
  }

  setPropertyStations()
  {
    this.calendarsService.stations().map((station: Station) =>
    {
      station.Disabled = station.Id == this.calendarsService.stationIdOfClickedActivity && this.isNew();
    });

    this._propertyStations = this.calendarsService.stations().filter((station: Station) => this.currentProperty?.StationLevels?.some(s => station.Id == s.StationId && this.currentActivity && s.MaxLevel > this.currentActivity.Level));
  }


  get propertyStations(): Station[]
  {
    return this._propertyStations;
  }

  get platformNameLabel(): string
  {
    let name = "Platform Provider"; // "Station";

    if (!this.isRadioBroadcastPropertyType())
    {
      name = "Platform Provider"; // "Internet";
    }

    return name;
  }


  startDateChange(event: MatDatepickerInputEvent<Date>)
  {
    if (this.commonService.today > this.currentActivity.StartDate)
    {
      if (!this.isNew())
      {
        //Revert date back to original if not NEW.
        this.form.controls["StartDate"].patchValue(this.data.StartDate, { emitEvent: false });
      }
      else
      {
        //Ensure StartDate is not less than today for NEW activities.
        this.form.controls["StartDate"].patchValue(this.commonService.today, { emitEvent: false });
      }
    }

    if (this.currentActivity?.StartDate > this.currentActivity?.EndDate)
    {
      //Ensure StartDate is not greater than EndDate.
      this.form.controls["EndDate"].patchValue(this.currentActivity.StartDate, { emitEvent: false });
    }

    if (this.currentActivity?.Recurrence?.RecurrenceType == this.RecurrenceTypeNone)
    {
      //Reset after save
      this.resetRecurrenceSummary();

      this.currentActivity.Recurrence.PatternStartDate = this.currentActivity.StartDate;
      this.currentActivity.Recurrence.FirstOccurrenceDate = this.currentActivity.StartDate;
    }
  }

  endDateChange(event: MatDatepickerInputEvent<Date>)
  {
    if (this.commonService.today > this.currentActivity.EndDate)
    {
      if (!this.isNew())
      {
        //Revert date back to original if not NEW.
        this.form.controls["EndDate"].patchValue(this.data.EndDate, { emitEvent: false });
      }
      else
      {
        //Ensure EndDate is not less than today for NEW activities.
        this.form.controls["EndDate"].patchValue(this.commonService.today, { emitEvent: false });
      }
    }

    if (this.currentActivity?.StartDate > this.currentActivity?.EndDate)
    {
      //Ensure StartDate is not greater than EndDate.
      this.form.controls["EndDate"].patchValue(this.currentActivity.StartDate, { emitEvent: false });
    }

    if (this.currentActivity?.Recurrence?.RecurrenceType == this.RecurrenceTypeNone)
    {
      //Reset after save
      this.resetRecurrenceSummary();

      this.currentActivity.Recurrence.PatternEndDate = this.currentActivity.EndDate;
      this.currentActivity.Recurrence.LastOccurrenceDate = this.currentActivity.EndDate;
    }
  }

  recurrence(): void
  {
    if (!this.dialogIsOpen)
    {
      try
      {
        this.dialogIsOpen = true;
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "50rem";
        dialogConfig.hasBackdrop = true;

        let recurrence = this.currentActivity.Recurrence;

        if (!recurrence)
        {
          recurrence = new Recurrence();
          recurrence.PatternStartDate = this.currentActivity.StartDate;
          recurrence.PatternEndDate = this.currentActivity.EndDate;
          recurrence.RecurrenceType = this.RecurrenceTypeNone;
          recurrence.Id = 0;
        }

        recurrence.MaximumMonths = this.configurationService.cbSettings().maxRecurrenceMonths;

        dialogConfig.data = recurrence;

        const dialogRef = this.dialog.open(RecurrenceComponent, dialogConfig);

        this.recurrenceClosedSubscription = dialogRef.afterClosed().subscribe((result: Recurrence) =>
        {
          this.dialogIsOpen = false;

          if (result)
          {
            this.form.controls["Recurrence"].patchValue(result, { emitEvent: false });

            if (this.currentActivity?.Recurrence?.RecurrenceType == this.RecurrenceTypeNone)
            {
              this.currentActivity.Recurrence.PatternStartDate = this.currentActivity.StartDate;
              this.currentActivity.Recurrence.PatternEndDate = this.currentActivity.EndDate;
              this.currentActivity.Recurrence.FirstOccurrenceDate = this.currentActivity.StartDate;
              this.currentActivity.Recurrence.LastOccurrenceDate = this.currentActivity.EndDate;
            }
            else
            {
              //Set start date to new date that was chosen in recurrence              
              this.form.controls["StartDate"].patchValue(this.currentActivity.Recurrence.PatternStartDate, { emitEvent: false });

              this.currentActivity.Recurrence.FirstOccurrenceDate = this.currentActivity.Recurrence.PatternStartDate;

              //Set end date to new date that was chosen in recurrence              
              this.form.controls["EndDate"].patchValue(this.currentActivity.Recurrence.PatternEndDate, { emitEvent: false });

              this.currentActivity.Recurrence.LastOccurrenceDate = this.currentActivity.Recurrence.PatternEndDate;
            }

            //Reset after save
            this.resetRecurrenceSummary();

            if (result.RecurrenceType == this.RecurrenceTypeNone)
            {
              this.form.get('StartDate').enable({ emitEvent: false });
              this.form.get('EndDate').enable({ emitEvent: false });
            }
            else
            {
              this.form.get('StartDate').disable({ emitEvent: false });
              this.form.get('EndDate').disable({ emitEvent: false });
            }

            this.form.markAsDirty();
          }
        });
      }
      catch (e)
      {
        this.dialogIsOpen = false;
      }
    }
  }

  resetRecurrenceSummary()
  {
    //Reset after save
    this._recurrenceSummary = "";
    this._recurrenceTypeName = ""
  }

  get recurrenceTypeName(): string
  {
    if (!this._recurrenceTypeName)
    {
      this._recurrenceTypeName = "None";

      if (this.currentActivity && this.currentActivity?.Recurrence)
      {
        switch (this.currentActivity.Recurrence.RecurrenceType)
        {
          case this.RecurrenceTypeDaily:
            this._recurrenceTypeName = "Daily";
            break;
          case this.RecurrenceTypeMonthly:
            this._recurrenceTypeName = "Monthly";
            break;
          case this.RecurrenceTypeNone:
            this._recurrenceTypeName = "None";
            break;
          case this.RecurrenceTypeWeekly:
            this._recurrenceTypeName = "Weekly";
            break;
          case this.RecurrenceTypeYearly:
            this._recurrenceTypeName = "Yearly";
            break;
          default:
            this._recurrenceTypeName = "None";
            break;
        }
      }
    }

    return this._recurrenceTypeName;
  }

  get recurrenceSummary(): string
  {
    if (!this._recurrenceSummary)
    {
      this._recurrenceSummary = "None";

      if (this.currentActivity && this.currentActivity?.Recurrence)
      {
        if (this.currentActivity?.Recurrence?.RecurrenceType == this.RecurrenceTypeNone)
        {
          this._recurrenceSummary = `${this.recurrenceTypeName} | ${this.currentActivity.StartDate.toDateString()} - ${this.currentActivity.EndDate.toDateString()}`;
        }
        else
        {
          if (this.currentActivity?.Recurrence?.NoEndDate)
          {
            this._recurrenceSummary = `${this.recurrenceTypeName} | ${this.currentActivity.Recurrence.PatternStartDate.toDateString()} - No End Date`;
          }
          else if (this.currentActivity?.Recurrence?.EndAfter)
          {
            this._recurrenceSummary = `${this.recurrenceTypeName} | ${this.currentActivity.Recurrence.PatternStartDate.toDateString()} - Ends after ${this.currentActivity.Recurrence.Occurrences} occurrences`;
          }
          else
          {
            this._recurrenceSummary = `${this.recurrenceTypeName} | ${this.currentActivity.Recurrence.PatternStartDate.toDateString()} - ${this.currentActivity.Recurrence.PatternEndDate.toDateString()}`;
          }
        }
      }
    }

    return this._recurrenceSummary;
  }

  get dialogHeaderText(): string
  {
    let headerText = "Edit Activity Blocker";

    if (this.isNew())
    {
      headerText = "New Activity Blocker";
    }
    else
    {
      if (this.data.ClientName)
      {
        headerText = `${headerText} - ${this.data.ClientName}`;
      }
    }

    return headerText.truncate(55);
  }

  get disableRecurrenceButton(): boolean
  {
    const disable = this.currentActivity && this.currentActivity.CreatedExternally;

    return disable;
  }


  get canDeleteActivity(): boolean
  {
    let canDelete = false;

    canDelete = this.authorisationService.hasPermission(this.authorisationService.Permissions.DeleteActivityBlocker);

    return canDelete;
  }

  getFieldErrorMessage(fieldName: string)
  {
    let errmsg = "";

    switch (fieldName.toLowerCase().trim())
    {
      default:
        errmsg = `${fieldName} is a required field.`;
        break
    }

    return errmsg;
  }

  onCancelClick(): void
  {
    this.processingInProgress = false;

    this.dialogRef.close();
  }

  delete(event: any)
  {
    this.processingInProgress = true;
    this.processingMessage = "Deleting Activity Blocker, please wait...";

    if (event)
    {
      if (event.stopPropagation)
      {
        // stop event bubbling up
        event.stopPropagation();
      }

      //prevents browser from performing the default action for this element
      event.preventDefault();
    }
    const activity: Activity = this.currentActivity;

    if (activity)
    {
      //manually set fields that don't get set through binding
      activity.Property = this.currentProperty;

      let confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: false });
      confirmDialogRef.componentInstance.confirmMessage = `Are you sure you want to delete this activity blocker for ${activity.ClientName == null ? '' : activity.ClientName} ${activity.Product == null ? '' : activity.Product} (${activity.Id})?`;
      confirmDialogRef.componentInstance.confirmTitle = "Delete Activity Blocker?";

      this.dialogCloseSubscription = confirmDialogRef.afterClosed().subscribe((result: any) =>
      {
        this.dialogCloseSubscription.unsubscribe();

        if (result)
        {
          if (!activity.ProductCategories)
          {
            activity.ProductCategories = [];
          }

          this.deleteActivity.mutate(activity, {
            onSuccess: (data: any, variables: any, context: any) =>
            {
              this.processingInProgress = false;

              if (data)
              {
                if (data.IsSuccessful)
                {
                  if (data.Message)
                  {
                    this.commonService.notifySuccess("Deleted", data.Message);
                  }

                  this.commonService.activityChanged.next(this.data.Id);

                  this.dialogRef.close(data);
                }
                else
                {
                  if (data.Message)
                  {
                    const confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: true });
                    confirmDialogRef.componentInstance.confirmMessage = data.Message;
                    confirmDialogRef.componentInstance.confirmHeader = data.MessageHeader;
                    confirmDialogRef.componentInstance.confirmTitle = data.MessageTitle;
                    confirmDialogRef.componentInstance.showCancelButton = false;
                    confirmDialogRef.componentInstance.confirmText = "OK";
                  }

                  let errMsg = "Unable to save record.";

                  if (data.ErrorMessage)
                  {
                    errMsg = data.ErrorMessage;
                  }

                  this.commonService.notifyFailure("Error", errMsg, data.ExceptionMessage, data.ValidationExceptionMessage);
                }
              }
            },
            onError: (error, variables, context) =>
            {
              this.processingInProgress = false;
              this.loggerService.error(error.message + "  Unable to delete activity block " + activity.ClientName + ".");
            }
          })
        }
        else
        {
          this.processingInProgress = false;
        }

        confirmDialogRef = null;
      });
    }
  }

  async onSave(): Promise<void>
  {
    try
    {

      this.processingInProgress = true;
      this.processingMessage = "Saving Activity Blocker, please wait...";

      const activity: Activity = this.currentActivity;

      if (activity)
      {
        activity.IsGuaranteed = false;
        activity.IsSold = true;
        activity.IsUnConfirmedSold = false;
        activity.OnHold = false;
        activity.IsBlocker = true;

        //manually set disabled fields as they don't get set through binding
        activity.Property = this.currentProperty;
        activity.ProductCategories = [];
        activity.CanConflictProductCategory = true;

        activity.EndDate = this.form.get('EndDate').value;

        if (activity.EndDate < activity.StartDate)
        {
          activity.EndDate = activity.StartDate;
        }

        //create new recurrence object with default values if no recurrence
        if (!activity.Recurrence)
        {
          activity.Recurrence = new Recurrence();
          activity.Recurrence.DaysOfWeek = 0;
          activity.Recurrence.RecurrenceType = this.RecurrenceTypeNone;
          activity.Recurrence.Occurrences = 0;
          activity.Recurrence.Instance = 1;
          activity.Recurrence.Interval = 1;
          activity.Recurrence.NoEndDate = false;
          activity.Recurrence.EndAfter = false;
          activity.Recurrence.EndBy = true;
          activity.Recurrence.EveryWeekDay = false;

          activity.Recurrence.PatternStartDate = activity.StartDate;
          activity.Recurrence.PatternEndDate = activity.EndDate;
          activity.Recurrence.FirstOccurrenceDate = activity.StartDate;
          activity.Recurrence.LastOccurrenceDate = activity.EndDate;
          activity.Recurrence.DayOfMonth = 0;
          activity.Recurrence.MonthOfYear = 0;//month is zero indexed
          activity.Recurrence.MaximumMonths = this.configurationService.cbSettings().maxRecurrenceMonths;
          activity.Recurrence.OnSpecificDayOfMonth = true;
        }

        if (activity.Recurrence.PatternStartDate < activity.StartDate)
        {
          activity.Recurrence.PatternStartDate = activity.StartDate;
        }

        if (activity.Recurrence.PatternEndDate < activity.Recurrence.PatternStartDate)
        {
          activity.Recurrence.PatternEndDate = activity.Recurrence.PatternStartDate;
        }

        //must convert to Timezone neutral dates format AFTER all date object methods are called.
        activity.Recurrence.PatternStartDate = this.commonService.formatDateForService(activity.Recurrence.PatternStartDate);
        activity.Recurrence.PatternEndDate = this.commonService.formatDateForService(activity.Recurrence.PatternEndDate);
        activity.Recurrence.FirstOccurrenceDate = this.commonService.formatDateForService(activity.Recurrence.FirstOccurrenceDate);
        activity.Recurrence.LastOccurrenceDate = this.commonService.formatDateForService(activity.Recurrence.LastOccurrenceDate);
        activity.EndDate = this.commonService.formatDateForService(activity.EndDate);
        activity.StartDate = this.commonService.formatDateForService(activity.StartDate);
        activity.CreationDate = this.commonService.formatDateForService(activity.CreationDate);
        activity.DropDead = this.commonService.formatDateForService(this.commonService.ensureDateIsNotOnWeekend(activity.EndDate));
        activity.LastUpdated = this.commonService.formatDateForService(activity.LastUpdated);
        activity.OnHoldDate = this.commonService.formatDateForService(this.commonService.ensureDateIsNotOnWeekend(activity.OnHoldDate));

        if (activity.SoldDate)
        {
          //must convert to Timezone neutral dates format AFTER all date object methods are called.
          activity.SoldDate = this.commonService.formatDateForService(activity.SoldDate);
        }

        if (!this.isNew() || activity.Id > 0)
        {
          this.updateActivity.mutate(activity, {
            onSuccess: (data: any, variables: any, context: any) =>
            {
              this.processingInProgress = false;

              if (data)
              {
                if (data.IsSuccessful)
                {
                  if (data.Message)
                  {
                    this.commonService.notifySuccess("Saved", data.Message);
                  }

                  if (this.closeAfterSave)
                  {
                    this.dialogRef.close(data);
                  }

                  else
                  {
                    this.activitySaved.emit(data);

                    this.form.markAsPristine();
                    this.form.markAsUntouched();
                  }

                  this.commonService.activityChanged.next(this.data.Id);
                }
                else
                {
                  if (data.Message)
                  {
                    const confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: true });
                    confirmDialogRef.componentInstance.confirmMessage = data.Message;
                    confirmDialogRef.componentInstance.confirmHeader = data.MessageHeader;
                    confirmDialogRef.componentInstance.confirmTitle = data.MessageTitle;
                    confirmDialogRef.componentInstance.showCancelButton = false;
                    confirmDialogRef.componentInstance.confirmText = "OK";

                    if (data.CssClassName)
                    {
                      confirmDialogRef.addPanelClass(data.CssClassName);
                    }
                  }

                  else
                  {
                    let errMsg = "Unable to save record.";

                    if (data.ErrorMessage)
                    {
                      errMsg = data.ErrorMessage;
                    }

                    this.commonService.notifyFailure("Error", errMsg, data.ExceptionMessage, data.ValidationExceptionMessage);
                  }
                }
              }
            },
            onError: (error, variables, context) =>
            {
              this.processingInProgress = false;
              this.loggerService.error(error.message + "  Unable to create activity block " + activity.ClientName + ".");
            }
          })
        }
        else
        {
          activity.CreationDate = new Date();
          activity.SoldDate = new Date();

          this.createActivity.mutate(activity, {
            onSuccess: (data: any, variables: any, context: any) =>
            {
              this.processingInProgress = false;

              if (data)
              {
                if (data.IsSuccessful)
                {
                  if (data.Message)
                  {
                    this.commonService.notifySuccess("Saved", data.Message);
                  }

                  if (this.closeAfterSave)
                  {
                    this.dialogRef.close(data);
                  }

                  else
                  {
                    this.activitySaved.emit(data);

                    //replaced current activity object with serialised version from the server with all the newly created Ids
                    this.data = JSON.parse(data.ActivityJson);
                    this.setupActivity(this.currentActivity);

                    this.form.markAsPristine();
                    this.form.markAsUntouched();
                  }

                  this.commonService.activityChanged.next(this.data.Id);
                }

                else
                {
                  if (data.Message)
                  {
                    const confirmDialogRef: MatDialogRef<ConfirmationDialog> = this.confirmDialog.open(ConfirmationDialog, { disableClose: true });
                    confirmDialogRef.componentInstance.confirmMessage = data.Message;
                    confirmDialogRef.componentInstance.confirmHeader = data.MessageHeader;
                    confirmDialogRef.componentInstance.confirmTitle = data.MessageTitle;
                    confirmDialogRef.componentInstance.showCancelButton = false;
                    confirmDialogRef.componentInstance.confirmText = "OK";

                    if (data.CssClassName)
                    {
                      confirmDialogRef.addPanelClass(data.CssClassName);
                    }
                  }

                  else
                  {
                    let errMsg = "Unable to save record.";

                    if (data.ErrorMessage)
                    {
                      errMsg = data.ErrorMessage;
                    }

                    this.commonService.notifyFailure("Error", errMsg, data.ExceptionMessage, data.ValidationExceptionMessage);
                  }
                }
              }
            },
            onError: (error, variables, context) =>
            {
              this.processingInProgress = false;
              this.loggerService.error(error.message + "  Unable to create activity " + activity.ClientName + ".");
            }
          })
        }
      }
      else
      {
        if (this.closeAfterSave)
        {
          this.dialogRef.close(null);
        }
      }
    }
    catch (ex)
    {
      this.processingInProgress = false;
    }
  }

  ngOnDestroy()
  {
    if (this.dialogCloseSubscription)
    {
      this.dialogCloseSubscription.unsubscribe();
    }

    if (this.recurrenceClosedSubscription)
    {
      this.recurrenceClosedSubscription.unsubscribe();
    }
  }











}
