import { AsyncPipe, DatePipe, NgClass } from '@angular/common';
import { Component, Injector, Input, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { fileExcelIcon } from "@progress/kendo-svg-icons";
import { QueryClient } from '@tanstack/angular-query-experimental';
import { Subscription, debounceTime, distinctUntilChanged, fromEvent } from 'rxjs';
import { CheckBoxFilterComponent } from '../../../shared/components/kendo-checkbox-filter.component';
import { DropDownListFilterComponent } from '../../../shared/components/kendo-dropdownlist-filter.component';
import { KendoGridComponent } from '../../../shared/components/kendo-grid.component';
import { NumericTextBoxFilterComponent } from '../../../shared/components/kendo-numerictextbox-filter.component';
import { TextBoxFilterComponent } from '../../../shared/components/kendo-textbox-filter.component';
import { ActivitiesInfo } from '../../../shared/models/common.models';
import { SaveResult } from '../../../shared/models/result.model';
import { AuthorisationService } from '../../../shared/services/authorisation.service';
import { CommonDataService } from '../../../shared/services/common-data.service';
import { CommonService } from '../../../shared/services/common.service';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { KendoGridService } from '../../../shared/services/kendo-grid.service';
import { AdministrationService } from '../../administration/administration.service';
import { ActivitiesComponent } from '../../calendars/activities/activities.component';
import { Station } from '../../calendars/calendars.models';
import { CalendarsService } from '../../calendars/calendars.service';
import { SearchActivity } from '../search.models';

@Component({
  selector: 'search-activities',
  templateUrl: './search-activities.component.html',
  styleUrls: ['./search-activities.component.scss'],
  providers: [KendoGridService],
  standalone: true,
  imports: [MatPaginatorModule, FormsModule, GridModule, NgClass, ExcelModule, SVGIconModule, NumericTextBoxFilterComponent, TextBoxFilterComponent, DropDownListFilterComponent, CheckBoxFilterComponent, MatButtonModule, MatIconModule, AsyncPipe, DatePipe]
})
export class SearchActivitiesComponent extends KendoGridComponent<SearchActivity> implements OnDestroy, OnInit
{
  private onSavedSubscription: Subscription;
  private onDataFetchedSubscription: Subscription;

  private queryClient = new QueryClient();
  public configurationService = inject(ConfigurationService);


  public dialog: MatDialog;
  public dialogConfig = new MatDialogConfig();
  public dialogIsOpen = false;
  protected editCloseSubscription: Subscription;

  private _isOnline = false;
  private _stations: Station[] = [];
  private _startDate: Date;
  private _endDate: Date;

  public icons = {
    excel: fileExcelIcon
  };

  @Input() public gridIsChild: boolean = false;

  @Input() get isOnline(): boolean
  {
    return this._isOnline;
  }
  set isOnline(val: boolean)
  {
    this._isOnline = val;
  }

  @Input() get stations(): Station[]
  {
    return this._stations;
  }
  set stations(value: Station[])
  {
    this._stations = value;
  }

  @Input() get startDate(): Date
  {
    return this._startDate;
  }
  set startDate(value: Date)
  {
    this._startDate = value;
  }

  @Input() get endDate(): Date
  {
    return this._endDate;
  }
  set endDate(value: Date)
  {
    this._endDate = value;
  }



  @ViewChild('adminForm', { static: true }) currentForm: NgForm

  constructor(public kendoGridService: KendoGridService,
    private calendarsService: CalendarsService,
    public commonService: CommonService,
    public commonDataService: CommonDataService,
    protected inj: Injector,
    public administrationService: AdministrationService,
    private authorisationService: AuthorisationService)
  {
    super(kendoGridService, "CreateActivity", "DeleteActivity", "api/Calendar/GetSearchActivitiesServer", "UpdateActivity", authorisationService?.currentuser.ChangedByUserId, "GetSearchActivities");

    this.CanAdd = false;
    this.CanEdit = this.authorisationService.hasPermission(this.authorisationService.Permissions.EditActivityImplementation)
      || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditActivitySales)
      || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditActivityStationSpecific);
    this.CanDelete = false;
    this.CanExport = true;

    this.dialog = inj.get(MatDialog);

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.hasBackdrop = true;
    this.dialogConfig.width = "90%";
    this.dialogConfig.height = "97vh";
  }

  override ngOnInit(): void
  {
    this.form = this.currentForm;

    //Need these for server paging
    this.view = this.kendoGridService;
    this.kendoGridService.serverPaging = true;
    this.gridState.take = 10;

    //let params: Parameter[] = [{ name: "locationGroupId", value: this.selectedLocationGroup.Id }, { name: "exposureTypeId", value: 0 }];
    //this.serviceParameters = params;

    this.onSavedSubscription = this.OnSaved.subscribe((data: any) =>
    {
      if (data.IsSuccessful)
      {
        this.calendarsService.resetHttpCache();
        this.calendarsService.resetCalendarData();
        this.calendarsService.dataResetFromActivitySave = true;

        if (data.Message)
        {
          this.commonService.notifySuccess("Success", data.Message);
        }
      }
      else
      {
        let errMsg = "Unable to save record.";

        if (data.ErrorMessage)
        {
          errMsg = data.ErrorMessage;
        }

        this.commonService.notifyFailure("Error", errMsg, data.ExceptionMessage, data.ValidationExceptionMessage);
      }
    });

    this.onDataFetchedSubscription = this.OnDataFetched.subscribe((data: SearchActivity[]) =>
    {
      if (data && data.length > 0)
      {
        //must change date values to proper date for grid filtering to work.
        data.map(e =>
        {
          if (e.StartDate)
          {
            e.StartDate = new Date(e.StartDate);
          }

          if (e.EndDate)
          {
            e.EndDate = new Date(e.EndDate);
          }

          if (e.CreationDate)
          {
            e.CreationDate = new Date(e.CreationDate);
          }

          if (e.DropDead)
          {
            e.DropDead = new Date(e.DropDead);
          }
        });
      }
    });

    //call after subscriptions
    super.ngOnInit();
  }


  activityHover(event: any, id: number)
  {
    fromEvent(event.target, event.type)
      .pipe(
        debounceTime(this.configurationService.cbSettings().cacheDebounceTime),
        distinctUntilChanged()
      ).subscribe(() =>
      {
        if (event)
        {
          if (event.stopPropagation)
          {
            // stop event bubbling up
            event.stopPropagation();
          }

          //prevents browser from performing the default action for this element
          event.preventDefault();
        }

        this.queryClient.prefetchQuery({
          queryKey: ["Activity", id],
          queryFn: () => this.calendarsService.getActivity(id),
          //staleTime: this.configurationService.cbSettings().prefetchQueryStaleTime
        });
      });
  }

  editItem(activity: SearchActivity)
  {
    if (event)
    {
      if (event.stopPropagation)
      {
        // stop event bubbling up
        event.stopPropagation();
      }

      //prevents browser from performing the default action for this element
      event.preventDefault();
    }

    if (this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewActivityImplementation)
      || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewActivitySales)
      || this.authorisationService.hasPermission(this.authorisationService.Permissions.ViewActivityStationSpecific)
      || (activity.Id > 0 && (this.authorisationService.hasPermission(this.authorisationService.Permissions.EditActivitySales)
        || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditActivityImplementation)
        || this.authorisationService.hasPermission(this.authorisationService.Permissions.EditActivityStationSpecific))))
    {
      if (!this.dialogIsOpen)
      {
        this.dialogIsOpen = true;

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "95%";
        dialogConfig.maxWidth = "1280px";
        dialogConfig.hasBackdrop = true;

        let dialogRef: any = null;

        const actInfo: ActivitiesInfo = new ActivitiesInfo();
        actInfo.id = activity.Id;

        dialogConfig.data = actInfo;

        dialogRef = this.dialog.open(ActivitiesComponent, dialogConfig);

        this.editCloseSubscription = dialogRef.afterClosed().subscribe((result: SaveResult) =>
        {
          this.dialogIsOpen = false;

          if (result && result.IsSuccessful)
          {
            this.kendoGridService.read(this.gridState);

            this.calendarsService.resetHttpCache();
            this.calendarsService.resetCalendarData();
            this.calendarsService.dataResetFromActivitySave = true;
          }
        });
      }
    }
  }

  override ngOnDestroy()
  {
    if (this.onSavedSubscription)
    {
      this.onSavedSubscription.unsubscribe();
    }

    if (this.onDataFetchedSubscription)
    {
      this.onDataFetchedSubscription.unsubscribe();
    }

    if (this.editCloseSubscription)
    {
      this.editCloseSubscription.unsubscribe();
    }

    super.ngOnDestroy();

  }

  page(event: PageEvent)
  {
    this.gridState.take = event.pageSize;
    this.gridState.skip = (event.pageIndex * event.pageSize);
    this.kendoGridService.read(this.gridState);
  }







}
